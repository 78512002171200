.contentContainer {
  margin-top: 20px;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: 10px auto;
}

.itemsContainer {
  box-shadow: 0 0 4px var(--gray-8);
  border-radius: 4px;
  padding: 20px;
  border: 1px solid var(--gray-8);
}

.itemContainer__searchFormulaButton {
  background-color: white;
  height: 38px;
  font-weight: 800;
  padding: 4px;
  margin-top: 5px;
  box-shadow: 0 0 4px var(--gray-10);
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  width: 100%;
  color: var(--gray-6);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.itemContainer__searchFormulaButton:hover {
  background-color: var(--gray-10);
}

.itemsContainer__itemList {
  margin-top: 4px;
  display: grid;
  gap: 10px;
}

.itemsContainer__itemList__item {
  border: 1px solid var(--gray-8);
  box-shadow: 0 0 4px var(--gray-10);
  border-radius: 4px;
  padding: 10px;
}

.itemsContainer__itemList__item__title > label {
  display: grid;
  grid-template-columns: auto 1fr auto;
  text-align: center;
}

.itemsContainer__itemList__item__title__header {
  font-size: 1.125rem;
}

.itemsContainer__itemList__item__info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.itemsContainer__itemList__item__info__div {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid var(--gray-9);
  box-shadow: inset 0 0 4px var(--gray-10);
  border-radius: 4px;
  text-align: center;
}

.itemsContainer__itemList__item__info__div__title {
  border-bottom: 1px solid var(--gray-10);
  margin-bottom: 4px;
  font-size: 1rem;
}

.itemListContainer__selectByColorContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto auto;
  margin-bottom: 10px;
}

.itemListContainer__selectByColorContainer > button {
  height: 38px;
  margin-top: 1.25rem;
}

.collapseContainer {
  margin-bottom: 1.25rem;
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
}

.collapseButton {
  box-shadow: 0 0 4px var(--gray-8);
  border-radius: 4px;
  padding: 1rem;
  justify-content: center;
}

.collapseTitle {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
}

.collapseContent {
  padding: 0.625rem 1.25rem;
}

.collapseContent__infoParagraphy {
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 0.625rem;
  color: var(--gray-2);
}

.collapseContent__itemList {
  display: grid;
  gap: 10px;
}

.collapseContent__item {
  border: 1px solid var(--gray-10);
  box-shadow: 0 0 4px var(--gray-10);
  border-radius: 4px;
  padding: 10px;
}

.collapseContent__itemName {
  font-size: 1.125rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 0.625rem;
  color: var(--gray-4);
}

.selectColorLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.selectOptionBall {
  height: 20px;
  width: 20px;
  display: block;
  border-radius: 50%;
}

.selectAllContainer {
  margin-bottom: 10px;
}

.selectAllContainer > label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  user-select: none;
}

.commissionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.25rem 0;
}

.commissionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.commissionBlock > p {
  position: relative;
  font-size: 0.625rem;
  margin: 5px 0;
}

.commissionBlock--notSelected {
  opacity: 0.4;
}

.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
}

.ball > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
}

.ball > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
}

.ball > p {
  position: absolute;
  color: white;
  font-weight: 800;
  font-size: 1.25rem;
  text-shadow: 0 0 0.1875rem var(--gray-0);
}

.itemListContainer__li__withoutRange {
  color: var(--red-4);
  font-size: 18px;
  text-align: center;
}

.loadingContainer {
  height: 300px;
  justify-content: center;
}

.modalSearchFormula_closeContainer {
  position: absolute;
  top: 5px;
  right: 5px;
}

.modalSearchFormula_closeContainer > button {
  background-color: var(--red-4);
  color: white;
  font-weight: 800;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.modalSearchFormula_input {
  margin-top: 10px;
}

.modalSearchFormula_searchButton {
  margin-top: 5px;
  width: 100%;
}

@media (max-width: 426px) {
  .itemsContainer__itemList__item__info {
    grid-template-columns: 1fr 1fr;
  }

  .itemsContainer__itemList__item__info > .itemsContainer__itemList__item__info__div:last-child {
    grid-column: 1/-1;
  }

  .itemListContainer__selectByColorContainer {
    gap: 0 10px;
    grid-template-columns: 1fr 0.6fr;
  }
  .itemListContainer__selectByColorContainer > button {
    grid-column: 1/-1;
    width: 100%;
  }
}
