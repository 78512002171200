.fieldsContainer {
  margin-top: 1.25rem;
  display: grid;
  gap: 0.625rem;
}

.fieldsContainer__statusField {
  justify-self: flex-end;
  min-width: 20%;
}

.saveButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}

@media (max-width: 768px) {
  .fieldsContainer__statusField {
    min-width: 30%;
  }
}

@media (max-width: 426px) {
  .fieldsContainer__statusField {
    min-width: 100%;
  }
}
