.main {
  position: relative;
  display: flex;
}

.arrowUp {
  position: absolute;
}

.arrowDown {
  position: absolute;
}
