:root {
  --sidebar-width: 320px;
}

.masterContainer {
  min-height: 100vh;
  width: 100%;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--sidebar-width);
  transition: 0.5s;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: var(--sidebar-width);
}

.topbar {
  height: 62px;
}

.content {
  flex: 1;
  padding: 20px;
  position: relative;
}

@media (max-width: 1024px) {
  .sidebar {
    transform: translateX(-100%);
    top: 62px;
  }

  .sidebar:global(.menuOpen) {
    transform: translateX(0);
    box-shadow: 2px 0 12px var(--gray-6);
  }

  .container {
    margin-left: 0;
  }

  .content:global(.menuOpen) {
    filter: blur(5px);
  }

  .content:global(.menuOpen)::after {
    content: "";
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    inset: 0;
  }
}
