.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.fieldsContainer > div:first-child {
  grid-column: 1 / 3;
}

.submitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 2.5rem;
  justify-content: center;
  height: 460px;
}
