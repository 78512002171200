.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.navigationContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.packingName {
  text-align: center;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.packingDescriptionContainer {
  grid-column: 1 / 3;
}

.submitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 2.5rem;
}
