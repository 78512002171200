.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingContainer > .loadingMessage {
  margin-top: 10px;
  font-size: 1.25rem;
  color: var(--green-5);
  text-align: center;
}
