.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  gap: 20px;
}

.insertButton {
  margin-top: 1.1875rem;
}

.contentContainer {
  margin-top: 20px;
}

.premiationItemList {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  flex-wrap: wrap;
}

.premiationItem {
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: grid;
  gap: 5px;
  border: 1px solid var(--gray-10);
  height: fit-content;
  min-width: 25rem;
}

.premiationItem__info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.premiationItem__info__title {
  font-size: 1.125rem;
  font-weight: 500;
}

.separator {
  display: block;
  height: 2px;
  background-color: var(--gray-9);
  margin: 2.5px 0px;
}

.removeButton {
  width: 100%;
  height: 30px;
  align-items: center;
  padding: 0;
}

@media (max-width: 768px) {
  .fieldsContainer {
    grid-template-columns: 1fr 1fr;
  }
  .insertButton {
    width: 100%;
    height: 38px;
  }
}

@media (max-width: 426px) {
  .fieldsContainer {
    grid-template-columns: 1fr;
  }
  .insertButton {
    margin-top: 0px;
  }
}
