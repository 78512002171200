.modalContent {
  padding-top: 5px;
  min-width: 60% !important;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.closeButton {
  font-size: 18px;
  border: 1px solid var(--gray-8);
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.closeButton:hover {
  transition: background-color 0.2s linear;
  background-color: var(--red-5);
  color: white;
}

.percentsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 0;
}

.cardMessage {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.inputsContainer {
  width: min-content;
  margin: auto;
  position: relative;
}

.colorInput {
  height: 80px;
  width: 80px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.colorInput::-webkit-color-swatch {
  border-radius: 50% 50% 0 0;
  border: 1px solid var(--gray-9);
}

.colorInput::-moz-color-swatch {
  border-radius: 50% 50% 0 0;
  border: 1px solid var(--gray-9);
}

.percentInput {
  border-radius: 8px 8px 4px 4px ;
  padding: 2px 12px;
  min-height: 30px;
  text-align: center;
  position: absolute;
  bottom: 4px;
}

.ballContainer {
  position: relative;
  display: flex;
  justify-content: center ;
  align-items: center;
  flex-direction: column;
}

.ball {
  position: relative;
  z-index: 2;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.ballBar {
  width: 125px;
  height: 20px;
  transform: translateY(-250%);
  border-radius: 2px;
}

.removeBallButton {
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: var(--red-5);
  animation: expandir 0.2s linear;
  display: none;
  cursor: pointer;
}

.ballPercent {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: bold;
  margin-top: -12px;
}

.ball:hover button {
  display: block;
}

.buttonSaveRange {
  width: 100%;
}

@keyframes expandir {
  from {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  to {
    width: 60px;
    height: 60px;
    opacity: 1;
  }
}