.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #cccccc;
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
}

.table th {
  font-size: 1.125rem;
  padding: 0.875rem;
  user-select: none;
  cursor: pointer;
}

.thWithSort {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickableLine {
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.clickableLine:hover {
  background-color: var(--gray-9);
}
