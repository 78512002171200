.userFormContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.configsContainer {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonSaveData {
  width: 100%;
  margin-top: 20px;
}

.buttonOpenModalReplicateSettings {
  width: 100%;
  margin-top: 10px;
}

.modalInputGroup {
  display: flex;
  gap: 20px;
}

.modal {
  min-width: 60vw;
}

.modalCloseContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}

.modalCloseContainer__button {
  color: white;
  font-weight: bold;
  padding: 3px 6px;
  background-color: red;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 var(--gray-8);
  border: 1px solid var(--gray-10);
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.modalCloseContainer__button:hover {
  background-color: rgb(167, 0, 0);
}

.modalCloseContainer__button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.modalInputGroup > div {
  flex: 1;
}

.modalButtonContainer {
  display: flex;
  justify-content: flex-end;
}