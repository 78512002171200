.lineCardMessage {
  display: block;
  font-size: 1.25rem;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-2);
  box-shadow: 0 2px 8px var(--gray-9);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: 10px auto;
}

.stickyButtonContainer {
  background-color: white;
  border-radius: 4px;
  position: sticky;
  padding: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 12px rgba(0, 0, 0, 0.1);
}
