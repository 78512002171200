.filtersContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 20px;
}

.filtersContainer__filterOrder {
    grid-column: 1/-1;
}

.filtersContainer__filterSellers {
    grid-column: 1/-1;
}

.filtersContainer__filterEnterprise {
    grid-column: 1/3;
}

.filtersContainer__search {
    grid-column: 1/-1;
    width: 100%;
}

.paginationContainer {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .filtersContainer {
        grid-template-columns: repeat(2, 1fr);
    }
    .filtersContainer__filterEnterprise {
        grid-column: 1/-1;
    }
}