.navContainer {
  display: flex;
  justify-content: flex-end;
}

.contentContainer {
  margin-top: 20px;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.editCategoryButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.paginationContainer {
  margin-top: 20px;
}
