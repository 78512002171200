.navContainer {
  display: flex;
  justify-content: flex-end;
}

.filtersContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
}

/* .filtersContainer__nomecnpjContainer {
  grid-column: 1/-1;
} */

.filtersContainer__periodoContainer > div {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.5rem;
  align-items: center;
}

.filtersContainer__periodoContainer > div > span {
  color: var(--gray-6);
}

.filtersContainer__buttonContainer {
  grid-column: 1/-1;
}

.filtersContainer__buttonContainer button {
  width: 100%;
  box-shadow: 0 2px 4px #b8b8b8;
}

.dataContainer {
  min-height: 500px;
}

.tableContainer {
  overflow-x: auto !important;
  box-shadow: inset 0 0 10px 8px #f5f5f5;
}

.paginationContainer {
  margin-top: 20px;
}

.loadingContainer {
  justify-content: center;
  min-height: 500px;
}

.editButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 3.75rem;
}

.optionsContainer {
  display: flex;
  gap: 0px;
  width: 100%;
  justify-content: center;
}

/* .filtersContainer__qtdDiasContainer {
  grid-column: 1/-1;
} */

.qtdDiasVencimentoField {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 38px;
  position: relative;
  border-radius: 4px;
  border: 1px solid transparent;
}

.qtdDiasVencimentoField > select {
  height: 100%;
  z-index: 5;
  border: 1px solid var(--gray-8);
  border-radius: 4px 0 0 4px;
  outline: none;
  padding: 0 5px;
  font-size: 18px;
  background-color: var(--gray-10);
  cursor: pointer;
}

.qtdDiasVencimentoField > input {
  height: 100%;
  border: 1px solid var(--gray-8);
  outline: none;
  margin-left: -1px;
  border-left: none;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  padding: 0 10px;
}

.qtdDiasVencimentoField > input::placeholder {
  color: var(--gray-7);
}

.qtdDiasVencimentoField:has(input:focus) > input {
  border-color: transparent;
}

.qtdDiasVencimentoField:has(input:focus) > select {
  border-color: transparent;
  border-right: 1px solid var(--gray-8);
}

.qtdDiasVencimentoField:has(input:focus) {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.qtdDiasVencimentoField.filled > input {
  border-color: transparent;
}

.qtdDiasVencimentoField.filled > select {
  border-color: transparent;
  border-right: 1px solid var(--gray-8);
}

.qtdDiasVencimentoField.filled {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.bolinhaStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bolinhaStatusContainer > span {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px var(--gray-8);
  border: 1px solid var(--gray-8);
}
