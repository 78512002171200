.navContainer {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 5px;
}

.percentsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 0;
}

.ballContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.ballContainer_selected .ball {
    animation: crescer-diminuir 1s infinite;
    border: 4px solid var(--gray-2);
}

.ballContainer_selected .ballBar {
    border: 4px solid var(--gray-2);
}

.ballRoot {
    width: 125px !important;
}

.ball {
    position: relative !important;
    height: 70px !important;
    width: 70px !important;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 2 !important;
}

.ballBar {
    width: 125px !important;
    height: 20px !important;
    border-radius: 4px !important;
}

.ballLetter {
    z-index: 10 !important;
}

.ballPercent {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-top: 10px;
}

.commissionPercentsContainer {
    width: 30%;
    margin: auto;
}

.commissionPercentsTable {
    width: 100%;
  border-collapse: collapse;
}

.commissionPercentsTable, .commissionPercentsTable td, .commissionPercentsTable th {
    border: 1px solid var(--gray-8);
}

.commissionPercentsTable th {
    font-size: 20px;
    padding: 10px;
}

.commissionPercentsTable td {
    font-size: 18px;
    padding: 5px;
    text-align: center;
}

.tdButtonOptions {
    display: flex;
    gap: 5px;
}

.buttonEditCommissionPercent, .buttonRemoveCommissionPercent {
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.buttonEditCommissionPercent {
    background-color: var(--green-2);
}

.buttonRemoveCommissionPercent {
    background-color: var(--red-2);
}

.buttonAddCommissionPercent {
    border: 1px solid var(--gray-8);
    font-size: 20px;
    width: 100%;
    border-top: none;
    padding: 5px;
    text-transform: uppercase;
    background-color: var(--gray-10);
    border-radius: 0 0 4px 4px;
    color: var(--gray-2);
    cursor: pointer;
    transition: filter 0.2s;
}

.buttonAddCommissionPercent:hover {
    filter: opacity(0.8);
}

.modalBallExample {
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 5px;
    align-items: center;
}

.modalBallExample__line {
    display: block;
    height: 10px;
    background-color: var(--gray-10);
}

.modalBallExample__ball {
    display: block;
    height: 20px;
    width: 40px;
    border-radius: 8px;
}

.modalFormContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
}

.modalFormContainer__percent {
    grid-column: 2;
}

.modalButtonsContainer {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}

@keyframes crescer-diminuir {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }