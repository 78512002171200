.container {
  position: fixed;
  z-index: 9999;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  display: none;
}

.container:global(.opened) {
  display: flex;
  justify-content: center;
  padding: 40px;
}
