.form {
  display: grid;
  gap: 20px;
}

.stepsNavigationContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.stepsNavigationContainer > button {
  border: 1px solid var(--gray-2);
  font-size: 1rem;
  padding: 0.3125rem 0.625rem;
  text-align: center;
  border-radius: 4px;
  min-width: 6.875rem;
  cursor: pointer;
}

.stepsNavigationContainer > button:global(.active) {
  border-color: var(--green-5);
  box-shadow: 0 0 4px var(--green-2);
  color: var(--green-2);
}

.fieldset {
  margin-top: 1.25rem;
  min-height: 282px;
}

.itemDetailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.itemDetailsContainer > .fieldDiv[data-grid="item"] {
  grid-column: 1/-1;
}

.itemDetailsContainer > .fieldDiv[data-grid="price"] {
  grid-column: 1/2;
}

.itemDetailsContainer > .fieldDiv[data-grid="finalPrice"] {
  grid-column: 2/3;
}

.addMicroContainer {
  display: flex;
  gap: 1.25rem;
}

.addMicroContainer > div:first-child {
  flex: 1;
}

.addPackingContainer {
  display: flex;
  gap: 1.25rem;
}

.addPackingContainer > div:first-child {
  flex: 1;
}

.addButton {
  margin-top: calc(1rem + 4.2px);
  height: 38px;
}

.tableContainer {
  margin-top: 1.25rem;
}

.table th {
  padding: 0.625rem;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.deleteButtonOnTable {
  margin: auto;
  padding: 0.5rem;
  min-width: 100%;
  font-size: 14px;
  height: 2rem;
}

.stepNavigateButtonContainer {
  display: flex;
}

.stepNavigateButtonContainer > button:last-child {
  margin-left: auto;
}

.ocultErrorToStyleButton {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-top: 4px;
  color: var(--red-6);
  visibility: hidden;
}

@media (max-width: 768px) {
  .itemDetailsContainer > .fieldDiv[data-grid="autoCalcPrice"] {
    grid-column: 2/-1;
  }

  .itemDetailsContainer > .fieldDiv[data-grid="status"] {
    grid-column: 3/-1;
  }
}

@media (max-width: 426px) {
  .itemDetailsContainer > .fieldDiv[data-grid="price"] {
    grid-column: 1/-1;
  }

  .itemDetailsContainer > .fieldDiv[data-grid="finalPrice"] {
    grid-column: 1/-1;
  }

  .itemDetailsContainer > .fieldDiv[data-grid="profitMargin"] {
    grid-column: 1/-1;
  }

  .itemDetailsContainer > .fieldDiv[data-grid="maximumDiscount"] {
    grid-column: 1/-1;
  }

  .itemDetailsContainer > .fieldDiv[data-grid="autoCalcPrice"] {
    grid-column: 1/-1;
  }

  .itemDetailsContainer > .fieldDiv[data-grid="status"] {
    grid-column: 1/-1;
  }

  .addMicroContainer {
    flex-direction: column;
    gap: 10px;
  }

  .addMicroContainer > div:last-child > button {
    margin-top: 0;
    width: 100%;
  }

  .addPackingContainer {
    flex-direction: column;
    gap: 10px;
  }

  .addPackingContainer > div:last-child > button {
    margin-top: 0;
    width: 100%;
  }
}
