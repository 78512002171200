.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: container 0.2s forwards;
  min-width: auto;
  max-width: 500px;
  border: 4px solid var(--gray-2);
}

@keyframes container {
  from {
    transform: translate3d(0, -20px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
