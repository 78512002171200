.anexarContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
}

.inputFileContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-9);
  border-radius: 4px;
  overflow: hidden;
  gap: 0.625rem;
}

.inputFileContainer__button {
  height: 38px;
  width: max-content;
  padding: 0 20px;
  background-color: var(--green-2);
  border-radius: 0px 4px 4px 0;
  color: var(--gray-10);
  font-size: 1rem;
}

.arquivoContainer {
  margin-top: 10px;
}

.loadingContainer {
  justify-content: center;
  min-height: 500px;
}

.linkDownload {
  display: block;
  width: 100%;
  color: rgb(0, 127, 211);
  min-height: 20px;
  border-radius: 4px;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid var(--gray-9);
  text-transform: lowercase;
}
