.itemListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checksContainer {
  display: flex;
  gap: 10px;
  margin-bottom: -5px;
}

.checkSelectAll {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkSelectAll > input {
  cursor: pointer;
}

.itemContainer {
  display: grid;
  grid-template-columns: auto 1fr;
}

.checkContainer {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-8);
  border-radius: 4px 0 0 4px;
  border-right: none;
  cursor: pointer;
}

.checkContainer > input {
  cursor: pointer;
}

.item {
  border: 1px solid var(--gray-8);
  border-radius: 0 4px 4px 0;
}

.mainCollapseContent > span:global(.open):last-child {
  overflow: visible !important;
}

.clickableCollapseArea {
  width: 100%;
  height: 38px;
  align-items: center;
  display: flex;
}

.clickableCollapseArea > button {
  cursor: pointer;
  padding: 0 20px;
  font-size: 18px;
  color: var(--gray-6);
  font-weight: bold;
}

.formContainer {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px 20px;
  margin-top: -10px;
  grid-template-areas:
    "item item item item item item"
    "costPrice costPrice costPrice sellPrice sellPrice sellPrice"
    "category ggf packaging icms freight premiation"
    "profitMargin profitMargin profitMargin grossMargin grossMargin grossMargin";
}

.formContainer [data-grid="item"] {
  grid-area: item;
}

.formContainer [data-grid="costPrice"] {
  grid-area: costPrice;
}

.formContainer [data-grid="sellPrice"] {
  grid-area: sellPrice;
}

.formContainer [data-grid="category"] {
  grid-area: category;
}

.formContainer [data-grid="ggf"] {
  grid-area: ggf;
}

.formContainer [data-grid="packaging"] {
  grid-area: packaging;
}

.formContainer [data-grid="profitMargin"] {
  grid-area: profitMargin;
}

.formContainer [data-grid="grossMargin"] {
  grid-area: grossMargin;
}

.formContainer [data-grid="icms"] {
  grid-area: icms;
}

.formContainer [data-grid="freight"] {
  grid-area: freight;
}

.formContainer [data-grid="premiation"] {
  grid-area: premiation;
}

.saveButtonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 20px;
}

.saveButton {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}

.modalReplicate__title {
  text-align: center;
  color: var(--gray-6);
  font-size: 18px;
}

.modalReplicate__buttonsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.modalReplicate__buttonsContainer__button {
  width: 100%;
}

@media (max-width: 768px) {
  .formContainer {
    grid-template-areas:
      "item item item item item item"
      "costPrice costPrice costPrice sellPrice sellPrice sellPrice"
      "category category ggf ggf packaging packaging"
      "icms icms freight freight premiation premiation"
      "profitMargin profitMargin profitMargin grossMargin grossMargin grossMargin";
  }
}

@media (max-width: 426px) {
  .formContainer {
    grid-template-columns: 1fr 1fr !important;
    grid-template-areas:
      "item item"
      "costPrice sellPrice"
      "category ggf"
      "packaging icms"
      "freight premiation"
      "profitMargin profitMargin"
      "grossMargin grossMargin";
  }
}
