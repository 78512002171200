.label {
  display: block;
  margin-bottom: 2px;
  font-size: 1rem;
  letter-spacing: 1.2px;
  color: var(--gray-6);
}

.cleanInputNumber::-webkit-inner-spin-button,
.cleanInputNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.fieldset {
  border: none;
  padding: 20px;
  border-radius: 4px;
}

.fieldsetLegend {
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 2px;
  color: var(--gray-3);
  width: 100%;
  border-bottom: 1px solid var(--gray-3);
}
