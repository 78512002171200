.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px 20px;
}

.statusContainer {
  justify-self: flex-end;
  min-width: 25%;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  justify-content: center;
  height: 500px;
}
