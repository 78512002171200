.formContainer {
  margin-top: 1.25rem;
}

.supervisorContainer {
  display: flex;
  gap: 20px;
}

.supervisorContainer > div:first-child {
  min-width: 200px;
}

.supervisorContainer > div:last-child {
  flex: 1;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
}
