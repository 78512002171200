.fieldsContainer {
  display: grid;
  gap: 10px 20px;
  grid-template-columns: 1fr 1fr;
}

.fieldProduct {
  grid-column: 1/-1;
}

.searchButtonContainer {
  grid-column: 1/-1;
}

.searchButton {
  width: 100%;
  margin-top: 10px;
}

.contentContainer {
  margin-top: 20px;
}

.productContainer {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 2px;
}

.productContainer:not(:last-child) {
  margin-bottom: 20px;
}

.productContainer__title {
  padding: 20px;
  border-bottom: 2px solid var(--gray-10);
  text-align: center;
}

.productContainer__listData {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.productContainer__data {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  min-width: calc(33% - 10px);
  max-width: calc(33% - 10px);
}

.data__info {
  display: flex;
  gap: 5px;
  align-items: center;
}

.data__info__title {
  font-size: 1.125rem;
  font-weight: 500;
}

.paginationContainer {
  margin-top: 20px;
}
