.navContainer {
  display: flex;
  justify-content: flex-end;
}

.filterContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.filterContainer__priceRange {
  grid-column: 1/-1;
}

.contentContainer {
  margin-top: 1.25rem;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.editPriceRangeButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.loadingContainer {
  min-height: 400px;
  justify-content: center;
}

.paginationContainer {
  margin-top: 1.25rem;
}
