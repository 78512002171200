.filterContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.filterContainer > .fieldDiv[data-grid="clients"] {
  grid-column: 1/4;
}

.filterContainer > .fieldDiv[data-grid="sellers"] {
  grid-column: 4/7;
}

.filterContainer > .fieldDiv[data-grid="enterprises"] {
  grid-column: 1/4;
}

.filterContainer > .fieldDiv[data-grid="operationTypes"] {
  grid-column: 4/7;
}

.filterContainer > .fieldDiv[data-grid="premiationType"] {
  grid-column: 1/3;
}

.filterContainer > .fieldDiv[data-grid="formulaGroups"] {
  grid-column: 3/5;
}

.filterContainer > .fieldDiv[data-grid="orderNumber"] {
  grid-column: 5/7;
}

.filterContainer > .fieldDiv[data-grid="initialDate"] {
  grid-column: 1/3;
}

.filterContainer > .fieldDiv[data-grid="finalDate"] {
  grid-column: 3/5;
}

.filterContainer > .submitButtonContainer {
  grid-column: 5/-1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
}

.separatorListLabel {
  font-size: 0.875rem;
}
/* Styles of stepsContainer */

.stepsContainer {
  padding: 10px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.stepsContainer > button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
  max-width: 200px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.stepsContainer > button:hover {
  opacity: 0.5;
}

.stepsContainer > button > p:first-child {
  border: 1px solid black;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepsContainer > button:global(.active) > p:first-child {
  border-color: var(--input-color);
  color: var(--green-5);
  box-shadow: 0 0 4px var(--green-5);
}

.stepsContainer > button:global(.active) > p {
  color: var(--green-5);
}

/* Styles of dataForm */

/* .dataFormContainer {
  margin-top: 20px;
} */

.dataFormContainer__main {
  display: flex;
  gap: 1.25rem;
  height: 560px;
}

.dataFormContainer__listDiv {
  flex: 1;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
  border-radius: 4px 0 0 4px;
}

.defaultPaymentTypeContainer {
  padding: 10px;
}

.dataFormContainer__infoDiv {
  min-width: 18.75rem;
  height: 100%;
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
  border-radius: 4px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dataFormContainer__infoDiv > header {
  display: grid;
  grid-template-columns: 1fr;
}

.dataFormContainer__infoDiv > header > div {
  margin: auto;
  display: grid;
  gap: 0.625rem;
  min-width: 20rem;
}

.dataFormContainer__infoDiv > header .selectedInfo {
  font-size: 1.125rem;
}

.dataFormContainer__infoDiv > header .selectedInfo > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid var(--gray-8);
  padding: 5px;
}

.dataFormContainer__infoDiv > header .selectedInfo > p > span {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
}

.dataFormContainer__infoDiv > footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dataFormContainer__infoDiv > footer > div {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.dataFormContainer__infoDiv > footer > div > button {
  width: 50%;
}

.generateReportContainer {
  display: flex;
  gap: 5px;
}

.generateReportContainer > nav {
  position: relative;
}

.generateReportContainer > nav > span {
  font-size: 1rem;
  border: 1px solid var(--gray-6);
  border-radius: 4px;
  padding: 0.625rem;
  height: 2.375rem;
  background-color: var(--gray-10);
  color: var(--gray-4);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.generateReportContainer > nav > div {
  display: none;
  padding-top: 5px;
  position: absolute;
}

.generateReportContainer > nav ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: max-content;
  background-color: #ffffff;
  padding: 5px;
  box-shadow: 0 0 4px var(--gray-6);
  border-radius: 4px;
}

.generateReportContainer > nav ul button {
  width: 100%;
}

.generateReportContainer > nav:hover > div {
  display: flex;
}

.dataForm__clientList {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 10px;
}

.dataForm__clientList > li {
  box-shadow: 0 0 4px var(--gray-8);
  border-radius: 4px;
  padding: 0.625rem;
  background-color: white;
}

.dataForm__clientList > li > header {
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  color: var(--gray-2);
}

.dataForm__clientList > li > header::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--gray-10);
  margin: auto;
  margin-top: 10px;
  border-radius: 100%;
}

.dataForm__clientList__orderList {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dataForm__clientList__orderList > li {
  box-shadow: inset 0 0 4px var(--gray-8);
  border-radius: 4px;
}

.dataForm__clientList__orderList > li > header {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--gray-6);
  padding: 5px 10px;
  border-bottom: 1px solid var(--gray-8);
}

.dataForm__clientList__orderList__premiationList {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dataForm__clientList__orderList__premiationList > li {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 10px;
}

.dataForm__clientList__orderList__premiationList > li::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: var(--gray-8);
  left: 0;
  top: 0;
}

.premiatedCheckLabelContainer {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none;
  height: 100%;
  min-height: 2.5rem;
}

.premiatedCheckLabelContainer:global(.disabled) {
  cursor: not-allowed;
}

.premiatedCheckInput {
  display: none;
}

.premiatedCheckInput ~ span {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  border: 1px solid var(--gray-6);
  border-radius: 50%;
  transition: background-color 0.3s;
}

.premiatedCheckInput:checked ~ span {
  background-color: var(--input-color-blue);
}

.premiatedCheckInput:disabled ~ span {
  background-color: var(--gray-9);
}

.notesContainer {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
}

.notesContainer:hover > ul {
  display: flex;
  flex-direction: column;
}

.notesContainer > ul {
  display: none;
  position: absolute;
  width: max-content;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  z-index: 5000;
  padding: 5px;
  border: 1px solid var(--gray-8);
  border-radius: 4px 0 4px;
  color: var(--gray-1);
  box-shadow: 0 0 4px var(--gray-9);
}

.notesContainer > ul > li {
  padding: 5px 0;
}

.notesContainer > ul > li:not(:last-child) {
  border-bottom: 1px solid var(--gray-8);
}

.notesButton {
  border: 1px solid var(--gray-8);
  padding: 0.3125rem 0.625rem;
  border-radius: 0.25rem;
  user-select: none;
  cursor: help;
  color: var(--gray-6);
}

.paymentValueLabel,
.paymentTypeLabel {
  display: flex !important;
}

.paymentValueLabel > span {
  display: block;
  width: 80px;
}

.paymentValueLabel > input {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 0 5px;
  width: 11.5625rem;
  outline: none;
}

.paymentValueLabel > input:focus {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.paymentValueLabel > input:disabled {
  cursor: not-allowed;
  color: #5e5e5e;
  background-color: #fcfcfc !important;
}

.paymentValueLabel > input:global(.isInvalid) {
  border-color: var(--red-6);
  box-shadow: 0 0 0 1px var(--red-6);
}

.paymentTypeLabel > span {
  display: block;
  width: 80px;
}

.paymentTypeLabel > select {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 0 2px;
  width: 11.5625rem;
  outline: none;
}

.paymentTypeLabel > select:focus {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.paymentTypeLabel > select:disabled {
  cursor: not-allowed;
  color: #000000;
  background-color: #fafafa !important;
}

.searchingMorePremiationsLoadingContainer {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center;
  gap: 10px;
  margin: 40px auto;
}

.searchingMorePremiationsLoadingContainer > :global(.loadingMessage) {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .dataFormContainer__main {
    flex-direction: column;
  }

  .dataFormContainer__infoDiv {
    height: auto;
  }

  .dataFormContainer__infoDiv > header > div {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .dataFormContainer__infoDiv .selectedInfo > p {
    justify-content: center;
    border: 2px solid var(--gray-8);
  }

  .dataFormContainer__infoDiv .selectedInfo > p {
    border-radius: 4px;
  }

  .dataFormContainer__infoDiv > footer {
    margin-top: 20px;
  }

  .dataFormContainer__infoDiv > footer > div {
    margin: 0;
  }

  .dataFormContainer__infoDiv > footer > .generateReportContainer {
    border: none;
  }

  .filterContainer > .fieldDiv[data-grid="premiationType"] {
    grid-column: 1/4;
  }

  .filterContainer > .fieldDiv[data-grid="formulaGroups"] {
    grid-column: 4/7;
  }

  .filterContainer > .fieldDiv[data-grid="orderNumber"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="initialDate"] {
    grid-column: 1/4;
  }

  .filterContainer > .fieldDiv[data-grid="finalDate"] {
    grid-column: 4/7;
  }

  .filterContainer > .submitButtonContainer {
    grid-column: 1/-1;
  }
}

@media (max-width: 426px) {
  .filterContainer > .fieldDiv[data-grid="clients"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="sellers"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="enterprises"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="operationTypes"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="premiationType"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="formulaGroups"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="orderNumber"] {
    grid-column: 1/-1;
  }

  .filterContainer > .fieldDiv[data-grid="initialDate"] {
    grid-column: 1/4;
  }

  .filterContainer > .fieldDiv[data-grid="finalDate"] {
    grid-column: 4/7;
  }

  .filterContainer > .submitButtonContainer {
    grid-column: 1/-1;
    flex-direction: column;
    width: 100% !important;
  }

  .generateReportContainer {
    width: 100%;
  }

  .generateReportContainer > nav,
  .generateReportContainer > nav > div,
  .generateReportContainer > nav > div > ul {
    width: 100%;
  }

  .generateReportContainer > button {
    flex: 1;
  }

  .filterContainer > .submitButtonContainer > button {
    width: 100%;
  }

  .dataForm__clientList > li > header {
    font-size: 1rem;
  }

  .dataForm__clientList__orderList > li > header {
    font-size: 1rem;
  }

  .dataForm__clientList__orderList__premiationList > li {
    gap: 5px;
    font-size: 10px;
  }

  .dataForm__clientList__orderList__premiationList > li > div {
    flex: 1;
  }

  .paymentValueLabel > span {
    width: 60px;
  }

  .paymentValueLabel > input {
    flex: 1;
    font-size: 12px;
  }

  .paymentTypeLabel > span {
    width: 60px;
  }

  .paymentTypeLabel > select {
    flex: 1;
    font-size: 12px;
  }

  .dataFormContainer__infoDiv .selectedInfo > p,
  .dataFormContainer__infoDiv .selectedInfo > p > span {
    font-size: 12px !important;
  }
}
