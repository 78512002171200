body {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: var(--gray-8);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--github-theme);
  border-radius: 0px;
}

.customScrollbar::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: var(--gray-8);
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--github-theme);
  border-radius: 0px;
}
