.inputColor {
  appearance: none;
  height: 38px;
  width: 100%;
  border: 2px solid var(--gray-8);
  cursor: pointer;
  background-color: var(--gray-10);
  padding: 0px 2px;
  border-radius: 4px;
}

.inputColor::-webkit-color-swatch {
  border-radius: 4px;
  border: none;
}

.inputColor::-moz-color-swatch {
  border-radius: 4px;
  border: none;
}
