.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  text-transform: uppercase;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: 10px auto;
}

.dataContainer {
  padding: 16px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 8px;
  border-radius: 4px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dataContainer__expense {
  line-height: 1.6;
}

.dataContainer__expense > li strong {
  color: var(--gray-0);
}

.dataContainer__voucherContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dataContainer__voucherValue {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dataContainer__voucher {
  height: 180px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  box-shadow: inset 0px 0px 8px 0px var(--gray-9);
  padding: 4px;
  border-radius: 4px;
}

.imageLoader {
  position: absolute;
}

.dataContainer__voucher > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  transition: opacity 0.1s linear;
}

.dataContainer__voucher > img:hover {
  opacity: 0.8;
}

.mapsFieldset {
  grid-column: 1/-1;
}

.buttonContainer {
  position: sticky;
  z-index: 2;
  background-color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px var(--gray-8);
  bottom: 20px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.historyButton {
  width: 100%;
  grid-column: 1/-1;
}

.approveButton,
.disapproveButton,
.cancelButton {
  width: 100%;
}

.cancelButton {
  grid-column: 1/-1;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
}

.modalReason > div:global(.modalContent) {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modalReasonFields {
  display: grid;
  gap: 0.625rem;
}

.modalReason__separator {
  height: 2px;
  background-color: var(--gray-10);
  border-radius: 100%;
  width: 100%;
  margin: auto;
}

.modalReason__buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.textareaReason {
  resize: none;
}

@media (max-width: 426px) {
  .dataContainer {
    padding: 8px 0 0 0;
    grid-template-columns: 1fr;
  }
  .modalReason > div:global(.modalContent) {
    min-width: 30rem;
  }
}
