.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.fieldsContainer {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625rem 1.25rem;
}

.fieldsContainer > div:last-child {
  width: 15%;
  justify-self: flex-end;
}

.saveButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
}

@media (max-width: 768px) {
  .fieldsContainer > div:last-child {
    width: 25%;
  }
}

@media (max-width: 426px) {
  .fieldsContainer > div:last-child {
    width: 100%;
  }
}
