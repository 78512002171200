.microsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
  gap: 20px;
}

.microItem {
  border: 1px solid var(--gray-8);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
}

.microItem__title {
  text-align: center;
  padding: .625rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--gray-8);
}

.microItem__details {
  padding: 20px;
  line-height: 25px;
}

.microItem__details > li {
  list-style: disc;
  margin-left: 20px;
  margin-right: 20px;
}

.microItem__detailInfo {
  font-size: 18px;
  font-weight: bold;
}

.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
}

.btnNewMicro {
  margin-left: auto;
}

.btnEditMicroContainer {
  border-top: 1px solid var(--gray-8);
  padding: .3125rem;
}

.btnEditMicroContainer__btnEdit {
  width: 100%;
}

.modalFormContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
}

.modalFormContainer__titleContainer {
  text-align: center;
  border-bottom: 2px solid var(--gray-10);
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.modalFormContainer__percent {
  grid-column: 2;
}

.modalButtonsContainer {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.modalFormContainer__newMicro {
  grid-column: 1/-1;
}