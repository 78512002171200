.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.pageContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2), 0 0 8px 0px rgba(0, 0, 0, 0.2) !important;
}

.selectedPageContainer {
  margin-top: 20px;
}

.pageNavigationContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  justify-content: center;
  gap: 10px;
}

.pageNavigationContainer > a {
  border: 1px solid var(--gray-2);
  font-size: 1rem;
  padding: 0.3125rem 0.625rem;
  text-align: center;
  border-radius: 4px;
}

.pageNavigationContainer > a:global(.active) {
  border-color: var(--green-5);
  box-shadow: 0 0 4px var(--green-2);
  color: var(--green-2);
  font-weight: 800;
  transition: box-shadow 0.2s;
}

@media (max-width: 1024px) {
  .pageNavigationContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 30px;
  }
  .pageNavigationContainer > a:nth-child(1) {
    grid-column: 1/3;
  }
  .pageNavigationContainer > a:nth-child(2) {
    grid-column: 3/5;
  }
}
@media (max-width: 426px) {
  .pageNavigationContainer {
    grid-template-columns: 1fr 1fr;
    padding: 0 1.875rem;
  }
  .pageNavigationContainer > a:nth-child(1) {
    grid-column: auto;
  }
  .pageNavigationContainer > a:nth-child(2) {
    grid-column: auto;
  }
}
