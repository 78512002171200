.container {
  width: 100%;
  padding-top: 1px;
}

.settingsContainer {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px, rgba(0, 0, 0, 0.04) 0px -4px 4px;
  margin-top: 20px;
}

.settingsTitle {
  text-align: center;
  margin: 10px;
  margin-top: 0px;
  text-transform: capitalize;
  color: var(--gray-8);
  border-bottom: 1px solid var(--gray-8);
}

.settingsFields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 10px 10px;
}

.labelSetting {
  display: flex !important;
  user-select: none;
  cursor: pointer;
  gap: 5px;
  padding: 5px 10px;
  text-transform: uppercase;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  height: 100%;
}

.labelSetting input {
  height: 1.2rem;
}

.labelSetting input {
  cursor: pointer;
}

.labelNumber {
  display: block !important;
  cursor: default;
}

.labelNumber > span {
  display: block;
  margin-bottom: 4px;
}

.labelNumber input {
  cursor: text;
}

.saveButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadContainer {
  min-height: 500px;
  justify-content: center;
}
