.container {
  width: 100%;
}

.header {
  text-align: center;
  background-color: var(--green-2);
  padding: 12px 0;
  color: var(--gray-10);
  border: 2px solid var(--gray-4);
  border-bottom: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid var(--gray-8);
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
}

.table th {
  font-size: 1.125rem;
  padding: 1rem;
}

.tableContainer:global(.responsiveTypeScroll) {
  overflow-x: auto;
  border: 1px solid var(--gray-8);
  border-top: 0;
}

.tableContainer:global(.responsiveTypeHeaderOnLeft) .table th,
.tableContainer:global(.responsiveTypeHeaderOnLeft) .table td {
  border: 1px solid var(--gray-8);
}

@media (max-width: 1024px) {
  .header {
    border: 2px solid var(--gray-8);
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table > thead {
    display: none;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table tr {
    display: block;
    margin-bottom: 5px;
    margin-top: -1px;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 140px;
    text-align: left;
    border-bottom: 0;
    min-height: 40px;
    word-break: break-word;
    grid-column: 1;
    width: 100% !important;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td[data-option] {
    padding: 0;
    border-bottom: 0 !important;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td[data-option] > [data-option-button] {
    margin: 0;
    width: 100%;
    border-radius: 0;
    min-height: 38px;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td[data-option] > [data-option-button] > * {
    visibility: hidden;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td:last-child {
    border-bottom: 1px solid var(--gray-4);
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td::before {
    content: attr(data-heading);
    position: absolute;
    inset: 0;
    width: 120px;
    display: flex;
    align-items: center;
    background-color: var(--green-2);
    color: var(--gray-10);
    font-size: 1.25rem;
    justify-content: center;
    padding: 0 5px;
    text-align: center;
  }

  .tableContainer:global(.responsiveTypeHeaderOnLeft) .table td[data-option]::before {
    width: auto;
    pointer-events: none;
    padding: 0;
    cursor: pointer;
    inset: 5px;
    background-color: transparent;
  }
}
