.container {
  position: relative;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
}

.container:global(.isOpened) .header {
  border-radius: 4px 4px 0 0;
}

.container:global(.isOpened) .input::placeholder,
.container:global(.isFilled) .input::placeholder {
  color: transparent;
}

.container:global(.isOpened) .header,
.container:global(.isFilled) .header {
  outline-color: var(--input-color);
  box-shadow: 0 0 3px var(--input-color);
}

.container:global(.isInvalid) .header {
  outline-color: var(--red-6);
  box-shadow: 0 0 3px var(--red-6);
}

.container:global(.isDisabled) .header {
  box-shadow: none;
  background-color: var(--gray-10);
  outline-color: transparent;
}

.container:global(.isDisabled) .input {
  pointer-events: none;
}

.header {
  display: flex;
  position: relative;
  align-items: center;
  outline: 2px solid transparent;
  border-radius: 4px;
  transition: border-radius 0.3s;
}

.input {
  min-height: 38px;
  border: none;
  flex: 1;
  font-size: 1rem;
  padding: 2px 8px;
  outline: none;
  background-color: transparent;
}

.input::placeholder {
  color: var(--gray-7);
  user-select: none;
}

.arrowCollapseButton {
  width: 28px;
  height: 38px;
  padding: 0 5px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: transparent;
  transition: background-color 0.15s;
}

.arrowCollapseButton:hover {
  background-color: var(--gray-12);
}

.arrowCollapse {
  transition: transform 0.3s;
}

.countIndicator {
  position: absolute;
  left: 8px;
}

.collapseContainer {
  z-index: 100;
  max-height: 0;
  min-height: 0;
  transition: min-height 0.3s;
  display: flex;
  overflow: hidden;
}

.collapseContainer > div:not(:nth-child(2)) {
  flex: 1;
}

.collapseContainer > div {
  height: 242px;
}

.list > ul {
  height: 100%;
  overflow-y: auto;
}

.list > ul > li {
  list-style: none;
}

.labelOption {
  display: block;
  padding: 0.5rem 0.625rem;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-8);
  transition: background-color 0.15s;
}

.labelOption:global(.active) {
  background-color: var(--gray-10);
}

.labelOption.leftLabelOption:global(.active),
.labelOption.rightLabelOption:global(.active) {
  border-left: 5px solid var(--green-6);
}

.labelOption > input[type="checkbox"] {
  display: none;
}

.labelOption:hover {
  background-color: var(--gray-10);
}

.actionList {
  display: flex;
  flex-direction: column;
  padding: 20px 5px;
  gap: 5px;
  border: 1px solid var(--gray-8);
  border-bottom: none;
  border-top: none;
}

.actionList > button {
  width: 28px;
  height: 28px;
  border: 1px solid var(--gray-8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: font-size 0.3s;
}

.actionList > button:hover {
  font-size: 18px;
}

.list > ul::-webkit-scrollbar {
  width: 5px;
}

.list > ul::-webkit-scrollbar-track {
  background-color: var(--gray-8);
}

.list > ul::-webkit-scrollbar-thumb {
  background-color: var(--green-2);
  border-radius: 0px;
}

.error {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-top: 4px;
  color: var(--red-6);
}

/* Controll */

.container:global(.isOpened) > .collapseContainer {
  min-height: 242px;
}

.container:global(.isOpened) .arrowCollapse {
  transform: rotate(-180deg);
}

/* Loading */

.loadingContainer {
  --size: 8.75rem;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 242px;
}

.spinner {
  display: inline-block;
  overflow: hidden;
  height: var(--size);
  width: var(--size);
  background-color: transparent;
}

@keyframes inside {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inside div {
  position: absolute;
  animation: inside 1s linear infinite;
  border-radius: 50%;
}

.inside {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.inside div {
  box-sizing: content-box;
  box-shadow: 0 4px 0 0 var(--green-6);
  height: calc(var(--size) * 0.8);
  width: calc(var(--size) * 0.8);
  top: calc(var(--size) * 0.1);
  left: calc(var(--size) * 0.1);
  transform-origin: calc(var(--size) * 0.4) calc(var(--size) * 0.41);
}
