.formContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
}

.formContainer__sellers {
  grid-column: 1/-1;
}

.formContainer__enterprises {
  grid-column: 1/-1;
}