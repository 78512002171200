.wrapper {
  border-radius: 12px;
  background: linear-gradient(25deg, #f2f2f2 5%, #e6e6e6 45%, #f9f9f9 60%);
  box-shadow: 0 0 10px 3px var(--gray-8);
  width: 80%;
  max-width: 460px;
  display: grid;
  padding: 40px 50px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--gray-9);
  margin: 25px 0;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.logoContainer p {
  font-size: 48px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--gray-1);
}

.logo {
  width: auto;
  height: 100px;
  object-fit: cover;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  margin-bottom: 1.125rem;
}

.label {
  text-align: center;
  color: var(--green-2);
  font-weight: 600;
}

.input {
  text-align: center;
}

.button {
  margin: auto;
  margin-top: 1.25rem;
}

@media (max-width: 600px) {
  .wrapper {
    position: fixed;
    inset: 0;
    max-width: none;
    width: 100%;
    display: block;
    border-radius: 0;
  }

  .form {
    margin-top: 24px;
  }
}
