.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
}

.navigateContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.625rem;
  align-items: center;
}

.checkButtonScreen {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative !important;height: 2.375rem;
  align-items: center;
  text-align: center;
  background-color: rgb(246, 246, 246);
}

.checkButtonScreen__indicator {
  display: block;
  height: 2rem;
  background-color: var(--green-2);
  border-radius: 4px;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  transition: all 0.6s ease;
}

.checkButtonScreen__indicator_detalhes {
  transform: translate(calc(100% - 4px), -50%);
}

.checkButtonScreen__itemScreen {
  position: relative;
  z-index: 2;
  font-weight: bold;
  color: var(--gray-10);
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkButtonScreen__itemScreen_inactive {
  color: var(--gray-8);
}

.timelineContainer {
  margin: 2.5rem 1.25rem;
}

.timelineContentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.timelineAuthorName {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  border-bottom: 1px solid var(--gray-8);
  color: var(--gray-4);
}

.loadingContainer {
  height: 600px;
  justify-content: center;
}

.exportExcelButton {
  width: 100%;
}

.decisionContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  position: sticky;
  z-index: 10;
  bottom: 10px;
  margin-top: 20px;
  padding: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 12px rgba(0, 0, 0, 0.1);
}

.modalExcelType__title {
  text-align: center;
  color: var(--gray-6);
  font-size: 18px;
}

.modalExcelType__buttonsContainer {
  display: flex;
  gap: 10px;
}

.modalExcelType__buttonsContainer__button {
  flex: 1;
}

.modalExcelType__buttonCancel {
  width: 100%;
  margin-top: 10px;
}