.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
}

.btnMicrosContainer {
  margin-left: auto;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px 20px;
  margin-top: 20px;
  grid-template-areas:
    "item item item item item item item item item item item item"
    "costPrice costPrice costPrice costPrice costPrice costPrice sellPrice sellPrice sellPrice sellPrice sellPrice sellPrice"
    "category category category ggf ggf ggf packaging packaging packaging profitMargin profitMargin profitMargin"
    "icms icms icms freight freight freight premiation premiation premiation grossMargin grossMargin grossMargin";
}

.fieldsContainer [data-grid="item"] {
  grid-area: item;
}

.fieldsContainer [data-grid="costPrice"] {
  grid-area: costPrice;
}

.fieldsContainer [data-grid="sellPrice"] {
  grid-area: sellPrice;
}

.fieldsContainer [data-grid="category"] {
  grid-area: category;
}

.fieldsContainer [data-grid="ggf"] {
  grid-area: ggf;
}

.fieldsContainer [data-grid="packaging"] {
  grid-area: packaging;
}

.fieldsContainer [data-grid="profitMargin"] {
  grid-area: profitMargin;
}

.fieldsContainer [data-grid="icms"] {
  grid-area: icms;
}

.fieldsContainer [data-grid="freight"] {
  grid-area: freight;
}

.fieldsContainer [data-grid="premiation"] {
  grid-area: premiation;
}

.fieldsContainer [data-grid="grossMargin"] {
  grid-area: grossMargin;
}

.fieldsContainer [data-grid="buttonSubmit"] {
  grid-area: submitButton;
  grid-column: 1/-1;
  margin-top: -10px;
}

.addItemButton {
  width: 100%;
}

.itensContainer {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.itensContainer > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 10px 20px;
}

.item {
  border: 1px solid var(--gray-8);
  padding: 10px;
  border-radius: 4px;
  display: flex;
  gap: 10px;
}

.item_selected {
  border: 1px solid var(--green-5);
  box-shadow: var(--green-3) 0px 2px 8px;
}

.item > div:first-child {
  flex: 1;
}

.item > div:first-child > div:first-child {
  border-bottom: 1px solid var(--gray-4);
}

.item__name {
  font-size: 1.125rem;
  font-weight: 500;
}

.item__prices {
  margin-top: 10px;

  padding-left: 20px;
}

.item__prices > li {
  list-style: circle !important;
}

.item_buttonSeparator {
  display: block;
  margin: 5px auto;
  height: 2px;
  width: 100%;
  border-radius: 100%;
  background-color: var(--gray-10);
}

.editButtonsContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.item_button {
  width: 100%;
  background-color: transparent !important;
}

@media (max-width: 426px) {
  .fieldsContainer {
    grid-template-columns: 1fr 1fr !important;
    grid-template-areas:
      "item item"
      "costPrice sellPrice"
      "category ggf"
      "packaging profitMargin"
      "icms freight"
      "premiation premiation"
      "grossMargin grossMargin";
  }
}
