.contentContainer {
  margin-top: 20px;
}

.permissionsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.permissionsContainer__separator {
  height: 4px;
  width: 100%;
  background-color: var(--gray-10);
  margin-bottom: 10px;
  border-radius: 100%;
}

.numericPermissionContainer {
  display: flex;
  gap: 10px;
}

.numericPermissionContainer > div:first-child {
  min-width: 24%;
}

.numericPermissionContainer > span:last-child {
  flex: 1;
}

.submitButtonContainer {
  grid-column: 1/-1;
  justify-self: flex-end;
}

.loadingContainer {
  min-height: 400px;
  justify-content: center;
}
