.container {
  z-index: 1000;
  background-color: var(--github-theme);
  display: flex;
  flex-direction: column;
}

.header {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray-2);
}

.header > img {
  width: 100%;
  max-width: 280px;
}

.menu {
  flex: 1;
  padding: 1rem 1.25rem 0 1.25rem;
  padding-bottom: 1.25rem;
  overflow-y: auto;
}

.menu::-webkit-scrollbar {
  width: 6px;
}

.menu::-webkit-scrollbar-track {
  background-color: var(--github-theme);
}

.menu::-webkit-scrollbar-thumb {
  background-color: var(--github-theme) !important;
  border-radius: 1px;
  border: 1px solid var(--github-theme);
}

.menu > li {
  display: block;
  margin-bottom: 8px;
}

.menuLink {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 1rem;
  width: 100%;
  padding: 0.625rem;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: var(--gray-10);
  text-align: left;
  user-select: none;
  cursor: pointer;
  transition: 0.3s;
  min-height: 35px;
}

.menuLink:hover,
.menuLink:global(.active) {
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-10);
}

.menuLinkDropdown {
  padding: 0.75rem 0.25rem;
  padding-left: 2.5rem;
  position: relative;
}

.menuLinkDropdown > li:not(:last-child) {
  margin-bottom: 10px;
}

.menuLinkDropdown::before,
.menuLinkDropdown::after {
  content: "";
  position: absolute;
  left: 1.25rem;
  background-color: var(--gray-6);
  filter: brightness(0.8);
}

.menuLinkDropdown::before {
  top: 0;
  height: 100%;
  width: 2px;
}

.menuLinkDropdown::after {
  bottom: 0;
  height: 2px;
  width: 1.25rem;
}

@media (max-width: 1024px) {
  .header {
    padding: 2.5rem 1.875rem;
  }
}
