.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 0.625rem;
  text-align: center;
}

.conferenceContainer {
  margin-top: 20px;
}

.decisionContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  position: sticky;
  bottom: 10px;
  margin-top: 20px;
  padding: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 12px rgba(0, 0, 0, 0.1);
  gap: 10px;
}

.exportExcelButton {
  flex: 1;
}

.withoutDataMessage {
  margin-top: 20px;
}

.loadingContainer {
  height: 540px;
  justify-content: center;
}

.modalExcelType__title {
  text-align: center;
  color: var(--gray-6);
  font-size: 18px;
}

.modalExcelType__buttonsContainer {
  display: flex;
  gap: 10px;
}

.modalExcelType__buttonsContainer__button {
  flex: 1;
}

.modalExcelType__buttonCancel {
  width: 100%;
  margin-top: 10px;
}
