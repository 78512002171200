.navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.itemsContainer {
  display: flex;
  gap: 1.25rem;
}

.itemsContainer > div:nth-child(1) {
  min-width: 17.5rem;
}

.itemsContainer > div:nth-child(2) {
  flex: 1;
}

.tableContainer {
  margin-top: 20px;
}

.tableItems td {
  padding: 0.3125rem 0.75rem;
}

.deleteItemButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 100%;
}

@media (max-width: 768px) {
  .itemsContainer {
    flex-direction: column;
    gap: 0.625rem;
  }
}
