.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.contentContainer {
  margin-top: 20px;
}

/* Styles of stepsContainer */

.stepsContainer {
  padding: 10px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.stepsContainer > button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
  max-width: 200px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.stepsContainer > button:hover {
  opacity: 0.5;
}

.stepsContainer > button > p:first-child {
  border: 1px solid black;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepsContainer > button:global(.active) > p:first-child {
  border-color: var(--input-color);
  color: var(--green-5);
  box-shadow: 0 0 4px var(--green-5);
}

.stepsContainer > button:global(.active) > p {
  color: var(--green-5);
}

/* Styles of dataForm */

/* .dataFormContainer {
  margin-top: 20px;
} */

.dataFormContainer__main {
  display: flex;
  gap: 1.25rem;
  height: 560px;
}

.dataFormContainer__listDiv {
  flex: 1;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
  border-radius: 4px 0 0 4px;
}

.selectAllContainer {
  padding: 20px 10px;
  padding-bottom: 10px;
}

.selectAllContainer > label {
  display: flex;
  gap: 5px;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  align-items: center;
  color: var(--gray-6);
  font-weight: 800;
}

.dataFormContainer__infoDiv {
  min-width: 18.75rem;
  height: 100%;
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
  border-radius: 4px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dataFormContainer__infoDiv > header {
  display: grid;
  grid-template-columns: 1fr;
}

.dataFormContainer__infoDiv > header > div {
  margin: auto;
  display: grid;
  gap: 0.625rem;
  min-width: 20rem;
}

.dataFormContainer__infoDiv > header .selectedInfo {
  font-size: 1.125rem;
}

.dataFormContainer__infoDiv > header .selectedInfo > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid var(--gray-8);
  padding: 5px;
}

.dataFormContainer__infoDiv > header .selectedInfo > p > span {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
}

.dataFormContainer__infoDiv > footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dataFormContainer__infoDiv > footer > div {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.generateReportContainer {
  border: 2px solid var(--gray-8);
  border-left: none;
  border-right: none;
  padding: 5px;
}

.generateReportContainer > button {
  width: 50%;
}

.lastOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px !important;
}

.lastOptionsContainer > button {
  width: 100% !important;
}

.lastOptionsContainer > div {
  display: flex;
  gap: 10px;
}

.lastOptionsContainer > div > button {
  flex: 1;
}

.dataForm__clientList {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 10px;
}

.dataForm__clientList > li {
  box-shadow: 0 0 4px var(--gray-8);
  border-radius: 4px;
  padding: 0.625rem;
  background-color: white;
}

.dataForm__clientList > li > header {
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  color: var(--gray-2);
}

.dataForm__clientList > li > header::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--gray-10);
  margin: auto;
  margin-top: 10px;
  border-radius: 100%;
}

.dataForm__clientList__orderList {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dataForm__clientList__orderList > li {
  box-shadow: inset 0 0 4px var(--gray-8);
  border-radius: 4px;
}

.dataForm__clientList__orderList > li > header {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--gray-6);
  padding: 5px 10px;
  border-bottom: 1px solid var(--gray-8);
}

.dataForm__clientList__orderList__premiationList {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dataForm__clientList__orderList__premiationList > li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10px;
}

.dataForm__clientList__orderList__premiationList > li::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: var(--gray-8);
  left: 0;
  top: 0;
}

.premiatedCheckLabelContainer {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none;
  height: 100%;
  min-height: 2.5rem;
}

.premiatedCheckLabelContainer:global(.disabled) {
  cursor: not-allowed;
}

.premiatedCheckInput {
  display: none;
}

.premiatedCheckInput ~ span {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  border: 1px solid var(--gray-6);
  border-radius: 50%;
  transition: background-color 0.3s;
}

.premiatedCheckInput:checked ~ span {
  background-color: var(--input-color-blue);
}

.premiatedCheckInput:disabled ~ span {
  background-color: var(--gray-9);
}

.paymentValueLabel,
.paymentTypeLabel {
  display: flex !important;
}

.paymentValueLabel > span {
  display: block;
  width: 80px;
}

.paymentValueLabel > input {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 0 5px;
  width: 11.5625rem;
  outline: none;
}

.paymentValueLabel > input:focus {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.paymentValueLabel > input:disabled {
  cursor: not-allowed;
  color: #5e5e5e;
  background-color: #fcfcfc !important;
}

.paymentValueLabel > input:global(.isInvalid) {
  border-color: var(--red-6);
  box-shadow: 0 0 0 1px var(--red-6);
}

.paymentTypeLabel > span {
  display: block;
  width: 80px;
}

.paymentTypeLabel > select {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 0 2px;
  width: 11.5625rem;
  outline: none;
}

.paymentTypeLabel > select:focus {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.paymentTypeLabel > select:disabled {
  cursor: not-allowed;
  color: #000000;
  background-color: #fafafa !important;
}

.searchingMorePremiationsLoadingContainer {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center;
  gap: 10px;
  margin: 40px auto;
}

.searchingMorePremiationsLoadingContainer > :global(.loadingMessage) {
  margin: 0 !important;
}

/* Modal (Start) */

.modalReason > div:global(.modalContent) {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalReason__separator {
  height: 2px;
  background-color: var(--gray-10);
  border-radius: 100%;
  width: 80%;
  margin: auto;
}

.modalReason__buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.textareaReason {
  resize: none;
}

/* Modal (End) */

@media (max-width: 768px) {
  .dataFormContainer__main {
    flex-direction: column;
  }

  .dataFormContainer__infoDiv {
    height: auto;
  }

  .dataFormContainer__infoDiv > header > div {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .dataFormContainer__infoDiv .selectedInfo:last-child {
    grid-column: 1/-1;
  }

  .dataFormContainer__infoDiv .selectedInfo > p {
    justify-content: center;
    border: 2px solid var(--gray-8);
  }

  .dataFormContainer__infoDiv .selectedInfo > p {
    border-radius: 4px;
  }

  .dataFormContainer__infoDiv > footer {
    margin-top: 20px;
  }

  .dataFormContainer__infoDiv > footer > div {
    margin: 0;
  }

  .dataFormContainer__infoDiv > footer > .generateReportContainer {
    border: none;
  }

  .dataFormContainer__infoDiv > footer > div > button {
    width: 50%;
  }
}

@media (max-width: 426px) {
  .dataForm__clientList > li > header {
    font-size: 1rem;
  }

  .dataForm__clientList__orderList > li > header {
    font-size: 1rem;
  }

  .dataForm__clientList__orderList__premiationList > li {
    gap: 5px;
    font-size: 10px;
  }

  .dataForm__clientList__orderList__premiationList > li > div {
    flex: 1;
  }

  .paymentValueLabel > span {
    width: 60px;
  }

  .paymentValueLabel > input {
    flex: 1;
    font-size: 12px;
  }

  .paymentTypeLabel > span {
    width: 60px;
  }

  .paymentTypeLabel > select {
    flex: 1;
    font-size: 12px;
  }

  .dataFormContainer__infoDiv .selectedInfo > p,
  .dataFormContainer__infoDiv .selectedInfo > p > span {
    font-size: 12px !important;
  }
}
