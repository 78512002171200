.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
}

.formContainer {
  display: flex;
  gap: 0 20px;
}

.formContainer__nome {
  flex: 1;
}

.formContainer__status {
  width: 20%;
}

.buttonSalvar {
  width: 100%;
  margin-top: 20px;
}

.nomeInput {
  text-transform: uppercase;
}