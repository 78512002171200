.container {
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.header {
  display: flex;
  justify-content: center;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 4px 4px 0 0;
  padding: 12px;
}

.contentContainer {
  padding: 0 1rem 1rem 1rem;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
}

.periodContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.3125rem;
}

.periodContainer__period {
  color: white;
  font-weight: 600;
}

.enterprisesContainer {
  margin-top: 0.375rem;
}

.searchButtonContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-column: 2;
  grid-row: 1/2;
  height: 100%;
}

.searchButtonContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--gray-0);
  border-radius: 4px;
  padding: 0.375rem;
  height: 100%;
  width: 1.75rem;
  border: 0.0625rem solid var(--gray-8);
  cursor: pointer;
  user-select: none;
}

.periodContainer input[type="date"] {
  border: none;
  border-bottom: 0.0625rem solid var(--date-input-color);
  padding: 0 4px;
  font-size: 0.875rem;
  color: var(--date-input-color);
  background-color: transparent;
  transition: border-color 0.3s;
  font-weight: 800;
}

.periodContainer input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: var(--date-svg-image);
}

.periodContainer input[type="date"]:focus {
  outline: none;
}

.searchContainer {
  color: white;
  font-weight: 600;
}

.searchContainer input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  text-align: center;
}

.searchContainer input:focus {
  outline: none;
}

.separator {
  display: block;
  background-color: white;
  height: 2px;
  width: 100%;
  margin: 10px auto;
  border-radius: 100%;
  opacity: 0.2;
}

.itemQuantityContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.itemQuantityContainer > p {
  color: white;
  font-weight: bold;
}

.itemQuantityContainer__select {
  border: 1px solid var(--gray-8);
  border-radius: 2px;
  cursor: pointer;
}

.itemQuantityContainer__select:focus {
  outline: none;
}

.tableContainer {
  margin-top: 0.625rem;
}

.table {
  background-color: white;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.emptyTableMessage {
  text-align: center;
  color: white;
  border: 1px solid var(--gray-10);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px, rgba(0, 0, 0, 0.23) 0px 1px 3px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1rem;
}

.loadingContainer {
  display: flex;
  justify-content: center;
}

.totalContainer {
  color: white;
  text-align: end;
}

.totalContainer > span:first-child {
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
  height: 0.0625rem;
  background-color: white;
}

.paginationContainer {
  margin-top: 1.25rem;
}

.paginationContainer nav {
  background-color: white;
  border-radius: 4px;
}

@media (max-width: 426px) {
  .filterContainer {
    flex-direction: column;
    gap: 0.625rem;
  }
  .tableContainer {
    border-radius: 4px;
    overflow-y: scroll;
  }
}
