.container {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.header__title {
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  gap: .625rem;
}

.collapseButton {
  color: var(--gray-6);
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid var(--gray-8);
  border-radius: 8px;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.collapseButton:hover {
  background-color: var(--gray-10);
}