.dadosContratoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
}

.dadosContratoContainer__contratado,
.dadosContratoContainer__gestorContrato {
  grid-column: 1/-1;
}

.dadosContratoContainer__periodoPagamentoContainer {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.statusContainer {
  grid-column: 1/-1;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  justify-content: center;
  height: 500px;
}

@media (max-width: 768px) {
  .dadosContratoContainer {
    grid-template-columns: 1fr;
  }
  .dadosContratoContainer__periodoPagamentoContainer {
    grid-template-columns: 1fr;
  }
}
