.stepsContainer {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.stepsContainer::before, .stepsContainer::after {
  content: "";
  display: block;
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  pointer-events: none;
}

.stepsContainer::before {
  background-color: var(--gray-9);
}

.stepsContainer::after {
  background-color: var(--green-2);
  width: calc(100% / 4);
  border-radius: .25rem;
  left: 0;
  transition: transform 0.2s linear;
  transform: translateX(calc(100% * var(--currentStep)));
}

.stepsContainer__step {
  cursor: pointer;
  flex: 1;
  height: 2.5rem;
  border-radius: 4px;
  transition: background-color 0.2s linear;
}

.stepsContainer__step:hover {
  background-color: rgb(248, 248, 248);
}

.stepsContainer__step:disabled {
  cursor: not-allowed;
}

.stepsContainer__step__name {
  font-size: 1.125rem;
  color: var(--gray-8);
  font-weight: bold;
  transition: color 0.2s linear, scale 0.2s linear;
}

.stepsContainer__step[data-is-current="true"] .stepsContainer__step__name {
  color: var(--green-2) !important;
  scale: 1.1;
}

.pagesContainer {
  margin-top: 1.25rem;
}