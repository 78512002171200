.container {
  display: block;
}

.label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.error {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-top: 4px;
  color: var(--red-6);
}
