.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 0.625rem;
}

.fieldsContainer {
  display: grid;
  gap: 1.25rem;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  height: 520px;
  justify-content: center;
}
