.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 5px;
}

.orderDetailsList {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}

.orderDetailsList__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.orderDetailsList__item:nth-child(1) {
  grid-column: 1/4;
}

.orderDetailsList__item:nth-child(2) {
  grid-column: 4/7;
}

.orderDetailsList__item:nth-child(3) {
  grid-column: 7/10;
}

.orderDetailsList__item:nth-child(4) {
  grid-column: 10/-1;
}

.orderDetailsList__item:nth-child(5) {
  grid-column: 1/5;
}

.orderDetailsList__item:nth-child(6) {
  grid-column: 5/9;
}

.orderDetailsList__item:nth-child(7) {
  grid-column: 9/-1;
}

.orderDetailsList__item:nth-child(8) {
  grid-column: 1/5;
}

.orderDetailsList__item:nth-child(9) {
  grid-column: 5/9;
}

.orderDetailsList__item:nth-child(10) {
  grid-column: 9/-1;
}

.orderDetailsList__item[data-field="motivo"] {
  grid-column: 1/-1;
}

.orderDetailsList__item[data-field="observacao"] {
  grid-column: 1/-1;
}

.orderDetailsList__item__title {
  font-size: 18px;
  font-weight: bold;
}

.orderDetailsList__item__detail {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 8px;
}

.imageList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  justify-content: center;
  gap: 10px;
  grid-auto-flow: dense;
}

.imageList__imageItem {
  display: flex;
  justify-content: center;
  min-width: 480px;
  max-height: 400px;
  height: 100%;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 8px;
  background-color: rgb(252, 252, 252);
}

.imageList__imageItem__image {
  padding: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  transition: opacity 0.1s linear;
}

.imageList__imageItem__image:hover {
  opacity: 0.8;
}

.buttonContainer {
  position: sticky;
  z-index: 2;
  background-color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px var(--gray-8);
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  margin-top: auto;
}

.modalReason > div:global(.modalContent) {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modalReason__separator {
  height: 2px;
  background-color: var(--gray-10);
  border-radius: 100%;
  width: 80%;
  margin: auto;
}

.modalReason__buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.textareaReason {
  resize: none;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}