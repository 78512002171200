.navContainer {
  margin-top: -0.625rem;
  margin-bottom: 1.25rem;
}

.submitContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}
