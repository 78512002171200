.container {
  width: 100%;
}

.collapseHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.content {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--muted-foreground);
}

.items {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.button {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  font-weight: normal;
}

.buttonIndented {
  padding-left: calc(1rem * var(--level));
}

.icon {
  flex-shrink: 0;
  transition: transform 0.2s;
}

.iconExpanded {
  transform: rotate(90deg);
}

.expanded {
  padding: 0.5rem 0;
}
