.container {
  display: flex;
  flex-direction: column;
}

.form {
  display: grid;
  gap: 20px;
}

.systemContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.dataContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.dateFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.logsSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.TableLogsContainer {
  margin-top: 20px;
}

.searchingLogsLoadingContainer {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .systemContainer {
    grid-template-columns: 1fr;
  }
  .dataContainer {
    grid-template-columns: 1fr;
  }
}
