.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px 20px;
}

.fieldAction {
  grid-column: 3;
  width: 72%;
  justify-self: flex-end;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}
