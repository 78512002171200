.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.fieldDiv[data-grid="description"] {
  grid-column: 1/-1;
}

.fieldDiv[data-grid="group"] {
  grid-column: 1/-1;
}

.fieldDiv[data-grid="marginPhysicalPerson"] {
  grid-column: 1/3;
}

.fieldDiv[data-grid="marginLegalPerson"] {
  grid-column: 3/5;
}

.fieldDiv[data-grid="interestApplied"] {
  grid-column: 1/3;
}

.fieldDiv[data-grid="daysUntilInterestCollection"] {
  grid-column: 3/5;
}

.fieldDiv[data-grid="minimumDueDate"] {
  grid-column: 1/2;
}

.fieldDiv[data-grid="dueDate"] {
  grid-column: 2/3;
}

.fieldDiv[data-grid="actionAfterDueDate"] {
  grid-column: 3/5;
}

.fieldDiv[data-grid="commissionType"] {
  grid-column: 1/2;
}

.fieldDiv[data-grid="maximumDiscount"] {
  grid-column: 2/3;
}

.fieldDiv[data-grid="freightValue"] {
  grid-column: 3/4;
}

.fieldDiv[data-grid="baseDate"] {
  grid-column: 4/5;
}

.fieldDiv[data-grid="useMarginNegotiation"] {
  grid-column: 1/2;
}

.fieldDiv[data-grid="chargeICMS"] {
  grid-column: 2/3;
}

.fieldDiv[data-grid="chargeInterestOnFreight"] {
  grid-column: 3/4;
}

.fieldDiv[data-grid="freightType"] {
  grid-column: 4/5;
}

.submitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 2.5rem;
}

@media (max-width: 768px) {
  .fieldDiv[data-grid="description"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="group"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="personType"] {
    grid-column: 1/3;
  }

  .fieldDiv[data-grid="interestType"] {
    grid-column: 3/5;
  }

  .fieldDiv[data-grid="chargeFreight"] {
    grid-column: 1/3;
  }

  .fieldDiv[data-grid="includeFreight"] {
    grid-column: 3/5;
  }

  .fieldDiv[data-grid="marginPhysicalPerson"] {
    grid-column: 1/3;
  }

  .fieldDiv[data-grid="marginLegalPerson"] {
    grid-column: 3/5;
  }

  .fieldDiv[data-grid="interestApplied"] {
    grid-column: 1/3;
  }

  .fieldDiv[data-grid="daysUntilInterestCollection"] {
    grid-column: 3/5;
  }

  .fieldDiv[data-grid="commissionType"] {
    grid-column: 1/3;
  }

  .fieldDiv[data-grid="maximumDiscount"] {
    grid-column: 3/5;
  }

  .fieldDiv[data-grid="freightValue"] {
    grid-column: 1/3;
  }

  .fieldDiv[data-grid="baseDate"] {
    grid-column: 3/5;
  }

  .fieldDiv[data-grid="useMarginNegotiation"] {
    grid-column: 1/3;
  }
  
  .fieldDiv[data-grid="chargeICMS"] {
    grid-column: 3/5;
  }
  
  .fieldDiv[data-grid="chargeInterestOnFreight"] {
    grid-column: 1/3;
  }
  
  .fieldDiv[data-grid="freightType"] {
    grid-column: 3/5;
  }
}

@media (max-width: 600px) {
  .fieldDiv[data-grid="marginPhysicalPerson"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="marginLegalPerson"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="interestApplied"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="daysUntilInterestCollection"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="minimumDueDate"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="dueDate"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="actionAfterDueDate"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="commissionType"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="maximumDiscount"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="freightValue"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="baseDate"] {
    grid-column: 1/-1;
  }

  .fieldDiv[data-grid="useMarginNegotiation"] {
    grid-column: 1/-1;
  }
  
  .fieldDiv[data-grid="chargeICMS"] {
    grid-column: 1/-1;
  }
  
  .fieldDiv[data-grid="chargeInterestOnFreight"] {
    grid-column: 1/-1;
  }
  
  .fieldDiv[data-grid="freightType"] {
    grid-column: 1/-1;
  }
}
