.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}

.submitButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 40px;
  justify-content: center;
  height: 460px;
}
