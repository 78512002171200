.fieldsContainer {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}

.fieldsContainer__userField {
  grid-column: 1/-1;
}

.fieldsContainer__statusField {
  grid-column: 1/-1;
  justify-self: flex-end;
  min-width: 20%;
}

.saveButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}

@media (max-width: 768px) {
  .fieldsContainer__statusField {
    min-width: 30%;
  }
}

@media (max-width: 426px) {
  .fieldsContainer {
    grid-template-columns: 1fr;
  }

  .fieldsContainer__statusField {
    min-width: 100%;
  }
}
