.container {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.filterContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.filterByFormulaContainer {
  grid-column: 1 / 3;
}

.formulaListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tableFormula td {
  padding: 0.3125rem 0.75rem;
}

.editFormulaButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.searchingFormulasLoadingContainer {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noFormulasMessage {
  display: block;
  font-size: 1.25rem;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-2);
  box-shadow: 0 2px 8px var(--gray-9);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.paginationContainer {
  margin-top: 20px;
}
