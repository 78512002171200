.button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.icon {
  position: absolute;
  right: 0.5rem;
  transition: 0.3s linear;
  pointer-events: none;
}

.icon:global(.open) {
  transform: rotateZ(90deg);
}

.content {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: 0.15s;
}

.content:global(.open) {
  transition: 0.5s;
  max-height: max-content;
}
