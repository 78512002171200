:root {
  /* TODO: GrayScale */
  --gray-0: #111111;
  --gray-1: #222222;
  --gray-2: #303030;
  --gray-3: #424242;
  --gray-4: #5b5b5b;
  --gray-5: #7b7b7b;
  --gray-6: #9a9a9a;
  --gray-7: #b6b6b6;
  --gray-8: #cccccc;
  --gray-9: #e4e4e4;
  --gray-10: #f2f2f2;
  /* TODO: GrayScale */

  /* TODO: GreenScale */
  --green-0: #002611;
  --green-1: #043d1d;
  --green-2: #116837;
  --green-3: #129536;
  --green-4: #0daf3b;
  --green-5: #07d641;
  --green-6: #00f845;
  /* TODO: GreenScale */

  /* TODO: RedScale */
  --red-0: #1a0000;
  --red-1: #410404;
  --red-2: #670707;
  --red-3: #8b0b0b;
  --red-4: #b80d0d;
  --red-5: #dc1010;
  --red-6: #eb2323;
  /* TODO: RedScale */

  /* TODO: OrangeScale */
  --orange-0: #1a0800;
  --orange-1: #411b04;
  --orange-2: #672a07;
  --orange-3: #8b3a0b;
  --orange-4: #b8490d;
  --orange-5: #dc5110;
  --orange-6: #eb6623;
  /* TODO: OrangeScale */

  /* TODO: YellowScale */
  --yellow-0: #e7aa10;
  --yellow-1: #f4cf09;
  --yellow-2: #ffe600;
  /* TODO: YellowScale */

  /* TODO: Others */
  --github-theme: #21262d;
  --input-color: #00ff15;
  --input-color-blue: #2684ff;
  --input-color-blue-1: #0063e6;
  --link: #0000ee;
  /* TODO: Others */
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* __Imports */

@import url("./reset.css");
@import url("./forms.css");
@import url("./containers.css");
@import url("./loadings.css");
@import url("./scrollbar.css");
@import url("./modal.css");
@import url("./commons.css");
@import url("./input-color.css");

/* __Imports */

@media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 10px;
  }
}
