.container {
  width: fit-content;
  height: fit-content;
  min-width: 500px;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 4px solid var(--gray-2);
}

.message {
  word-wrap: break-word;
  font-size: 18px;
  text-align: center;
  position: relative;
}

.separator {
  height: 1px;
  width: 80%;
  background-color: var(--gray-8);
  border-radius: 2px;
  margin: 20px auto;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  gap: 5px;
}

.button {
  padding: 10px;
  font-size: 14px;
  min-width: 80px;
}
