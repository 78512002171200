.formContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px 20px;
}

.formContainer__idPedido {
  grid-column: 1/4;
}

.formContainer__empresas {
  grid-column: 4/-1;
}

.formContainer__cliente {
  grid-column: 1/-1;
}

.formContainer__estado {
  grid-column: 1/3;
}

.formContainer__dataInicial {
  grid-column: 3/5;
}

.formContainer__dataFinal {
  grid-column: 5/-1;
}

.formContainer__buttonBuscarPremiacoes {
  width: 100%;
  margin-top: 10px;
  grid-column: 1/-1;
}