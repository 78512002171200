.container {
  display: flex;
  flex-direction: column;
}

.filtersContainer {
  display: grid;
  gap: 10px;
}

.filtersContainer__btnSearchSolicitation {
  width: 100%;
}

.dataContainer {
  margin-top: 0px;
}

.requestsTableContainer {
  display: grid;
  gap: 2.5rem;
}

.table {
  box-shadow: 0 0 8px var(--gray-8);
}

.requestsTable td {
  padding: 0.3125rem 0.75rem;
}

.seeRequestButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.loadingRequestListContainer {
  min-height: 500px;
  display: flex;
  justify-content: center;
}

.paginationContainer {
  margin-top: 20px;
}
