.wrapper {
  min-height: 100vh;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/images/auth-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 600px) {
  .wrapper {
    background-image: none;
  }
}
