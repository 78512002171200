.container {
  position: fixed;
  z-index: 10000;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.container:global(.show) {
  display: flex;
}

.modal {
  background-color: #ffffff;
  height: fit-content;
  padding: 20px;
  min-width: 31.25rem;
  margin-top: 20px;
  box-shadow: 0 2px 8px var(--gray-4);
  border-radius: 2px;
  position: relative;
  animation: modalOpen 0.1s linear forwards;
}

@keyframes modalOpen {
  from {
    top: -20px;
  }
  to {
    top: 0;
  }
}
