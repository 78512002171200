.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
}

.formContainer {
  display: flex;
  gap: 20px;
}

.formContainer__nameField {
  flex: 1
}

.formContainer__statusField {
  min-width: 15%;
}

.buttonSaveData {
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .formContainer__statusField {
    min-width: 25%;
  }
}

@media (max-width: 430px) {
  .formContainer {
    flex-direction: column;
    gap: 10px;
  }
}