.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  text-transform: uppercase;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: 10px auto;
}

.historyContainer {
  margin-top: 10px;
}

.timelineContainer {
  margin: 2.5rem 1.25rem;
}

.loadingContainer {
  justify-content: center;
  height: 400px;
}
