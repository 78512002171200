.formContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.item {
    position: relative;
    box-shadow: 0 0 4px var(--gray-8), 2px 0 4px var(--gray-8), -2px 0 4px var(--gray-8);
    border-radius: 4px;
}

.item > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.itemTitle {
    font-size: 24px;
    font-weight: bold;
}
  
.itemSeparator {
    display: block;
    margin: 10px 0;
    height: 2px;
    border-radius: 100%;
    width: 100%;
    background-color: var(--gray-8);
}
  
.itemOptionsContainer {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid var(--gray-9);
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    gap: 10px;
}
  
.itemOptionsContainer > span {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: all 0.1s linear;
    overflow: hidden;
}
  
.itemOptionsContainer:hover > span {
    top: 100%;
}

.commissionsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ballInfo {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: bold;
}

.editButtonsContainer {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.item_button {
    width: 100%;
}