.card {
  padding: 1.875rem;
  border: 1px solid var(--gray-8);
  border-radius: .5rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  min-width: 130px;
  min-height: 100%;
}

.card__figure {
  font-size: 30px;
}

.card__name {
  font-size: 1.25rem;
  letter-spacing: .0625rem;
}

.card__fullName {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: .3125rem;
  background-color: var(--github-theme);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: var(--gray-10);
  font-weight: bold;
  opacity: 0%;
}

.card:hover .card__fullName {
  opacity: 100%;
  transition: opacity 0.2s linear;
}