.button {
  display: block;
  padding: 0.625rem;
  font-size: 1rem;
  border: 1px solid var(--gray-6);
  border-radius: 4px;
  width: fit-content;
  min-width: 7.5rem;
  line-height: 1;
  cursor: pointer;
  background-color: var(--green-2);
  color: var(--gray-10);
  text-align: center;
  user-select: none;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.danger {
  background-color: var(--red-2);
}

.button.neutral {
  background-color: var(--gray-10);
  color: var(--gray-4);
}

.button.alert {
  background-color: var(--yellow-0);
  color: var(--gray-2);
}

.button.edit {
  background-color: var(--input-color-blue);
  color: var(--gray-10);
}

.button:global(.backButton) {
  background-color: var(--red-2);
  min-width: 3.125rem;
}
