.container {
  display: grid;
  gap: 20px;
}

.customerFieldsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.valueFieldsContainer {
  display: flex;
  gap: 20px;
}

.valueFieldsContainer > .field {
  flex: 1;
}

.tableFieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.tableFieldsContainer > .field {
  flex: 1;
}

.tableFieldsContainer > .tableFieldsContainer__validityTable {
  flex: 2;
}

.documentationFieldsContainer {
  display: flex;
  gap: 20px;
}

.documentationFieldsContainer > div {
  flex: 1;
}

.freightFieldsContainer {
  display: flex;
  gap: 20px;
}

.freightFieldsContainer > div {
  flex: 1;
}

.dueDateFieldsContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.lastFieldsContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

@media (max-width: 768px) {
  .valueFieldsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .documentationFieldsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 426px) {
  .typeAndDateFieldsContainer {
    grid-template-columns: 1fr;
  }

  .tableFieldsContainer {
    flex-direction: column;
  }

  .valueFieldsContainer {
    flex-direction: column;
  }

  .documentationFieldsContainer {
    flex-direction: column;
  }

  .dueDateFieldsContainer {
    grid-template-columns: 1fr 1fr;
  }
}
