.separator {
  display: block;
  height: 2px;
  background-color: var(--gray-9);
  border-radius: 100%;
  margin: 5px 5px;
}

.rangeTitle {
  font-size: 20px;
  text-align: center;
  margin-top: 5px;
  color: var(--gray-4);
  font-weight: 500;
  text-transform: uppercase;
}

.customLineCardMessage {
  margin: 10px 5px;
  border-color: var(--gray-9);
  color: var(--gray-7);
}

.searchFieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.searchFieldsContainer__tableFieldContainer {
  grid-column: 1/-1;
}

.searchButtonContainer {
  margin-top: 10px;
}

.searchButtonContainer > button {
  width: 100%;
}

.rangesMainContainer {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.rangeOptionsContainer {
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.rangesSelectedContainer {
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.rangeOptionsContainer__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 5px;
}

.rangesSelectedContainer__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 5px;
}

.ballContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  box-shadow: inset 0 0 4px var(--gray-8);
  border-radius: 4px;
  padding: 5px 10px;
  position: relative;
}

.ballContainer:hover {
  background-color: var(--gray-10);
}

.ballContainer__ball {
  height: 50px;
  width: 50px;
  border: 4px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s linear;
}

.ballContainer__margin {
  font-size: 1.125rem;
  user-select: none;
}
