.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 0.625rem;
}

.navContainer > div:last-child {
  display: flex;
  justify-content: flex-end;
}

.navContainer__title {
  text-transform: uppercase;
}

.filterContainer {
  margin-top: 20px;
}

.contentContainer {
  margin-top: 10px;
}

.itemTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.itemSeparator {
  display: block;
  margin: 10px auto;
  height: 2px;
  border-radius: 100%;
  width: 100%;
  background-color: var(--gray-8);
}

.itemOptionsContainer {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid var(--gray-9);
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.itemOptionsContainer > span {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: all 0.1s linear;
  overflow: hidden;
}

.itemOptionsContainer:hover > span {
  top: 100%;
}

.commissionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.commissionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.commissionBlock > p {
  position: relative;
  font-size: 0.625rem;
  margin: 5px 0;
}

.commissionBlock:hover .ball,
.commissionBlock:global(.isActive) .ball {
  opacity: 0.4;
}

.commissionBlock:hover > p,
.commissionBlock:global(.isActive) > p {
  transform: scale(1.5);
  transition: all 0.2s linear;
}

.commissionBlock:global(.isActive) > p {
  font-weight: 800;
}

.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
}

.ball > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
}

.ball > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
}

/* Details Container */
.detailsContainer {
  margin-top: 20px;
}

.detailsContainer__title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 800;
}

.detailsContainer__separator {
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--gray-8);
  margin: 5px 0;
}

.detailsContainer__contentContainer {
  display: grid;
  gap: 10px;
  max-height: 500px;
  overflow-y: auto;
  margin-top: 10px;
  box-shadow: inset 0 0 4px var(--gray-8);
  padding: 10px;
  border: 1px solid var(--gray-8);
}

.detailsContainer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--gray-6);
  padding-bottom: 1.25rem;
}

.detailsContainer__content__title {
  font-weight: 800;
  padding: 5px;
  background-color: var(--gray-9);
  width: 100%;
  text-align: center;
}

.detailsContainer__content__rangeDetailsContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1.25rem;
  min-width: 60%;
}

.detailsContainer__content__rangeDetailsContainer > .detailsContainer__content__rangeDetailsButton:last-child {
  justify-self: flex-end;
}

.detailsContainer__content__rangeDetailsButton {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  transition: all 0.2s linear;
  width: fit-content;
}

.detailsContainer__content__rangeDetailsButton:hover {
  background-color: rgb(247, 247, 247);
  transform: scale(1.1);
}

.detailsContainer__content__rangeDetails {
  display: flex;
  gap: 20px;
}

.detailsContainer__content__rangeDetails__detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.25rem;
}

.detailsContainer__content__rangeDetails__detail > p:first-child {
  font-weight: 800;
}

.detailsContainer__content__rangeDetails__ball {
  height: 2.5rem;
  width: 2.5rem;
  margin: auto;
  border-radius: 50%;
}

.detailsContainer__content__separator {
  display: block;
  width: 60%;
  height: 1px;
  margin: 10px 0;
  background-color: var(--gray-8);
}

.detailsContainer__content__participantsDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailsContainer__content__participantsDetails__detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.detailsContainer__content__participantsDetails__detail > p:first-child {
  font-weight: 800;
  font-size: 1.25rem;
}

/* Details Container */

.loadingContainer {
  min-height: 400px;
  justify-content: center;
}

@media (max-width: 426px) {
  .detailsContainer__content__rangeDetailsButton {
    padding: 0.625rem 0;
  }
  .detailsContainer__content__separator {
    width: 90%;
  }
}
