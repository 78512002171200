/* Estilos da bolinha da faixa */

.commissionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.commissionBlock > p {
  position: relative;
  font-size: 0.625rem;
  margin: 5px 0;
}

.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
}

.ball > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
}

.ball > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
}

.ball > p {
  position: absolute;
  color: white;
  font-weight: 800;
  font-size: 1.25rem;
  text-shadow: 0 0 0.1875rem var(--gray-0);
}

/* Estilos da bolinha da faixa */

.fieldsContainer {
  display: grid;
  gap: 0.625rem;
}

.buttonSearchData {
  width: 100%;
}

.title {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--gray-2);
}

.separator {
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--gray-8);
  border-radius: 100%;
  margin: 20px 0;
}

.dataContainer {
  margin-top: 1.25rem;
  display: grid;
  gap: 1rem;
  padding: 20px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.dataContainer__formulaInfo {
  text-align: left;
  display: grid;
  gap: 5px;
}

.dataContainer__formulaInfo__formula {
  font-size: 1.25rem;
  font-weight: 800;
  color: var(--gray-2);
}

.dataContainer__tableInfo {
  text-align: left;
  display: grid;
  gap: 5px;
}

.dataContainer__tableInfo__table {
  font-size: 1.25rem;
  font-weight: 800;
  color: var(--gray-2);
}

.dataContainer__tableInfo__validity {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--gray-4);
}

.formulaCollapse__container {
  padding: 20px;
  border-radius: 4px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
}

.rangesContainer {
  display: grid;
  gap: 1rem;
  padding: 20px;
  border-radius: 4px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
}

.rangeCollapse__container {
  padding: 20px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border-radius: 4px;
  border: 1px solid var(--gray-8);
}

.rangesContainer__ranges {
  display: flex;
  justify-content: center;
}

.loadingContainer {
  height: 400px;
  justify-content: center;
}
