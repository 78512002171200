.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px 20px;
}

.listContainer {
  margin-top: 10px;
  min-height: 420px;
}

.sellerListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--gray-8);
  border-radius: 2px;
  padding: 10px;
  height: 420px;
  overflow: auto;
}

.sellerListContainer > li {
  border: 1px solid var(--gray-8);
  padding: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
}

.removeSellerButton {
  background-color: var(--red-4);
  color: var(--gray-10);
  font-weight: 800;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.searchLoadingContainer {
  height: 400px;
  justify-content: center;
}

.linkLoadingContainer {
  height: 500px;
  justify-content: center;
}
