.react-select:global(__placeholder) {
  color: var(--gray-7) !important;
}

.react-select:global(__control.isSearchable) {
  cursor: text;
}

.react-select:global(__control.isFilled) {
  border-color: var(--default-border-color);
  box-shadow: 0 0 0 1px var(--default-border-color);
}

.react-select:global(__control.isInvalid) {
  border-color: var(--red-6);
  box-shadow: 0 0 0 1px var(--red-6);
}

.react-select:global(__control.isDisabled) {
  border: 1px solid var(--gray-8);
  background-color: var(--gray-10);
  box-shadow: none;
  cursor: not-allowed;
  pointer-events: auto;
}

.react-select:global(__multi-value__remove.isDisabled) {
  pointer-events: none;
}

.react-select:global(__control--is-focused) {
  border-color: var(--default-border-color) !important;
  box-shadow: 0 0 0 1px var(--default-border-color) !important;
}

.react-select:global(__option) {
  cursor: pointer !important;
}

.react-select:global(__indicator) {
  cursor: pointer;
}

.react-select:global(__indicator.isDisabled) {
  pointer-events: none;
}

.error {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-top: 4px;
  color: var(--red-6);
}
