.stepsContainer {
  padding: 10px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 0.625rem;
  flex-wrap: wrap;
}

.stepsContainer > .stepsContainer__step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
  max-width: 200px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.stepsContainer > .stepsContainer__step:hover {
  opacity: 0.5;
}

.stepsContainer > .stepsContainer__step:disabled:hover {
  opacity: 1;
  cursor: not-allowed;
}

.stepsContainer > .stepsContainer__step > .stepsContainer__step__page {
  border: 1px solid black;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepsContainer > .stepsContainer__step:disabled > .stepsContainer__step__page {
  border-color: var(--gray-8);
  color: var(--gray-8);
}

.stepsContainer > .stepsContainer__step.active > .stepsContainer__step__page {
  border-color: var(--input-color);
  color: var(--green-5);
  box-shadow: 0 0 4px var(--green-5);
}

.stepsContainer > .stepsContainer__step:disabled.active > .stepsContainer__step__page {
  border-color: var(--gray-8);
  color: var(--gray-8) !important;
  box-shadow: 0 0 4px var(--gray-8);
}

.stepsContainer > .stepsContainer__step.active > .stepsContainer__step__name {
  color: var(--green-5);
}

.stepsContainer > .stepsContainer__step:disabled.active > .stepsContainer__step__name {
  color: inherit;
}

.stepsContainer__page {
  min-height: 420px;
}

.stepsButtonNavigation {
  background-color: white;
  border-radius: 4px;
  position: sticky;
  bottom: 10px;
  margin-top: 20px;
  padding: 5px;
  display: flex;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 12px rgba(0, 0, 0, 0.1);
}

.stepsButtonNavigation__next {
  margin-left: auto;
}
