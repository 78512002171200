.modalAlert {
  border: 4px solid var(--yellow-0);
}

.alertTitle {
  text-align: center;
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: var(--yellow-0);
}

.alertMessage {
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem;
  word-break: break-word;
}
