.navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.dataContainer {
  margin-top: 1.25rem;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.editPaymentMethodButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.loadingContainer {
  min-height: 400px;
  justify-content: center;
}

.paginationContainer {
  margin-top: 1.25rem;
}

@media (max-width: 426px) {
  .navigationContainer {
    margin-bottom: 1.25rem;
  }
}
