.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.fieldsContainer {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.625rem 1.25rem;
}

.fieldsContainer > div:last-child {
  grid-column: 2;
  width: 50%;
  justify-self: flex-end;
}

.saveButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
}

@media (max-width: 1024px) {
  .fieldsContainer {
    grid-template-columns: 2fr 1fr;
  }
  .fieldsContainer > div:last-child {
    grid-column: 2;
    width: 60%;
  }
}

@media (max-width: 768px) {
  .fieldsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 426px) {
  .fieldsContainer {
    grid-template-columns: 1fr;
  }
  .fieldsContainer > div:last-child {
    grid-column: 1;
    width: 100%;
  }
}
