.fieldsContainer {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.25rem;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
}

.saveButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 426px) {
  .fieldsContainer {
    grid-template-columns: 1fr;
  }
}
