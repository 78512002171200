.errorContainer {
  height: calc(100vh - 102px);
  text-align: center;
}

.title {
  font-size: 12.5rem;
  margin: 0;
  color: #333;
}

.message {
  font-size: 3rem;
  color: #666;
}

.backLink {
  text-decoration: none;
  color: #007bff;
  font-size: 1.75rem;
  margin-top: 10px;
  display: block;
}

.backLink:visited {
  color: #007bff;
}

.backLink:hover {
  text-decoration: underline;
}
