.formContainer {
  margin-top: 1.25rem;
}

.clientContainer {
  display: flex;
  gap: 20px;
}

.clientContainer > div:first-child {
  min-width: 200px;
}

.clientContainer > div:last-child {
  flex: 1;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
}

@media (max-width: 768px) {
  .clientContainer {
    flex-direction: column;
    gap: 10px;
  }
}
