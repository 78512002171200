.navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.filterContainer {
  display: flex;
  gap: 20px;
}

.filterContainer > div:first-child {
  min-width: 200px;
}

.filterContainer > div:last-child {
  flex: 1;
}

.tableContainer {
  margin-top: 20px;
}

.tableClients td {
  padding: 0.3125rem 0.75rem;
}

.deleteClientButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
  width: 6.25rem;
}

@media (max-width: 768px) {
  .filterContainer {
    flex-direction: column;
    gap: 10px;
  }
}
