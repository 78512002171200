.container {
  position: relative;
}

.navContainer {
  border-radius: 4px 4px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid var(--gray-10);
}

.navContainer ul {
  height: 42px;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.navContainer ul > li {
  position: relative;
  z-index: 10;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--gray-6);
  user-select: none;
}

.navContainer ul > li:global(.active) {
  color: var(--gray-10);
}

.navContainer ul::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% / 3);
  background-color: var(--green-2);
  /* transition: margin-left 0.1s linear; */
  pointer-events: none;
}

.navContainer ul:global(.tablet)::after {
  margin-left: calc(100% / 3) !important;
}

.navContainer ul:global(.premiation)::after {
  margin-left: calc((100% / 3) * 2) !important;
}

.navContainer ul li {
  text-align: center;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
}

.contentContainer {
  padding-top: calc(2.625rem - 1.25rem);
}
