.container {
  display: flex;
  flex-direction: column;
}

.formContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.625rem 1.25rem;
}

.gridContainer [data-grid="basedTable"] {
  grid-column: 1/-1;
}

.gridContainer [data-grid="tableName"] {
  grid-column: 1/-1;
}

.gridContainer [data-grid="interest"] {
  grid-column: 1/4;
}

.gridContainer [data-grid="discount"] {
  grid-column: 4/7;
}

.gridContainer [data-grid="addFreight"] {
  grid-column: 7/10;
}

.gridContainer [data-grid="freightValue"] {
  grid-column: 10/13;
}

.gridContainer [data-grid="interestType"] {
  grid-column: 1/4;
}

.gridContainer [data-grid="daysChargeInterest"] {
  grid-column: 4/7;
}

.gridContainer [data-grid="commissionType"] {
  grid-column: 7/10;
}

.gridContainer [data-grid="campaign"] {
  grid-column: 10/13;
}

.gridContainer [data-grid="chargeICMS"] {
  grid-column: 1/5;
}

.gridContainer [data-grid="freightType"] {
  grid-column: 5/9;
}

.gridContainer [data-grid="chargeInterestOnFreight"] {
  grid-column: 9/13;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .gridContainer [data-grid="interest"] {
    grid-column: 1/5;
  }

  .gridContainer [data-grid="discount"] {
    grid-column: 5/9;
  }

  .gridContainer [data-grid="addFreight"] {
    grid-column: 9/13;
  }

  .gridContainer [data-grid="freightValue"] {
    grid-column: 1/5;
  }

  .gridContainer [data-grid="interestType"] {
    grid-column: 5/9;
  }

  .gridContainer [data-grid="daysChargeInterest"] {
    grid-column: 9/13;
  }

  .gridContainer [data-grid="situation"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="commissionType"] {
    grid-column: 1/5;
  }

  .gridContainer [data-grid="campaign"] {
    grid-column: 5/9;
  }

  .gridContainer [data-grid="chargeICMS"] {
    grid-column: 9/13;
  }

  .gridContainer [data-grid="freightType"] {
    grid-column: 1/7;
  }

  .gridContainer [data-grid="chargeInterestOnFreight"] {
    grid-column: 7/13;
  }
}

@media (max-width: 426px) {
  .gridContainer [data-grid="interest"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="discount"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="freightValue"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="addFreight"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="interestType"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="daysChargeInterest"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="situation"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="commissionType"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="campaign"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="chargeICMS"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="freightType"] {
    grid-column: 1/-1;
  }

  .gridContainer [data-grid="chargeInterestOnFreight"] {
    grid-column: 1/-1;
  }
}
