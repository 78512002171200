* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html * {
  font-family: "Ubuntu", "Tahoma", sans-serif;
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
  color: var(--gray-0);
}

ul,
li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

fieldset {
  border: none;
}

button {
  background-color: transparent;
  border: none;
}
