.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  align-items: flex-end;
}

.listContainer {
  margin-top: 20px;
}

.supervisorListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--gray-8);
  border-radius: 2px;
  padding: 10px;
  height: 380px;
  overflow: auto;
}

.supervisorListContainer > li {
  border: 1px solid var(--gray-8);
  padding: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
}

.removeSupervisorButton {
  background-color: var(--red-4);
  color: var(--gray-10);
  font-weight: 800;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}
