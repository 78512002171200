.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.collapsesContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentContainer {
  margin-top: 20px;
}

.collapseContainer {
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
}

.collapseInfoContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
}

.collapseInfoContainer > p {
  font-size: 1rem;
}

.premiationCollapse {
  box-shadow: 0 0 4px var(--gray-8);
  border-radius: 4px;
}

.premiationCollapse:global(.isOpen) {
  border-radius: 4px 4px 0 0;
}

.timelineContainer {
  margin: 2.5rem 1.25rem;
}

.historyContent time {
  text-align: end;
  display: block;
  margin-top: 15px;
}
