.buttonNewTableContainer {
  display: flex;
  justify-content: flex-end;
}

.tablesContainer {
  margin-top: 20px;
}

.tablesContainer > ul {
  border-radius: 4px;
  padding: 0.625rem;
  border: 1px solid var(--gray-8);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 0.875rem;
}

.tableItem {
  position: relative;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tableItem__situationIndicator {
  position: absolute;
  right: 5px;
  top: 10px;
  display: flex;
  justify-content: center;
  height: 12px;
  width: 12px;
  background-color: var(--red-5);
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid var(--gray-8);
}

.tableItem__situationIndicator:global(.active) {
  background-color: var(--green-5);
}

.tableItem__situationIndicator::after {
  content: "Inativo";
  display: none;
  color: var(--gray-2);
  width: max-content;
  position: absolute;
  bottom: calc(100% + 5px);
  background-color: white;
  padding: 2px 5px;
  border-radius: 4px;
  box-shadow: 0 0 3px var(--gray-8);
  z-index: 100;
}

.tableItem__situationIndicator:global(.active)::after {
  content: "Ativo";
}

.tableItem__situationIndicator:hover::after {
  display: block;
}

.tableItem__title {
  padding: 0 0.9375rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.tableItem__date {
  text-align: center;
  font-weight: 500;
}

.tableItem__divisor {
  display: block;
  height: 1px;
  width: 40%;
  background-color: var(--gray-10);
  margin: 10px auto;
}

.editButtonsContainer {
  display: flex;
  gap: 5px;
  margin-top: auto;
}

.tableItem__button {
  width: 100%;
  height: 2.375rem;
  border: 1px solid var(--gray-8);
  border-radius: 2px;
  font-weight: 400;
  background-color: transparent !important;
  transition: background-color 0.3s;
}

.tableItem__button:hover {
  background-color: var(--gray-10) !important;
}

.searchingMoreValidityContainer {
  grid-column: 1/-1;
  margin: 20px 0;
  flex-direction: row !important;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.searchingMoreValidityContainer span {
  margin-top: 0 !important;
}
