.fieldsContainer {
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.fieldsContainer [data-grid="minPrice"] {
  grid-column: 1/3;
}

.fieldsContainer [data-grid="maxPrice"] {
  grid-column: 3/5;
}

.fieldsContainer [data-grid="commissionValue"] {
  grid-column: 5/7;
}

.fieldsContainer [data-grid="table"] {
  grid-column: 1/4;
}

.fieldsContainer [data-grid="formula"] {
  grid-column: 4/7;
}

.fieldsContainer [data-grid="enterprise"] {
  grid-column: 1/3;
}

.fieldsContainer [data-grid="color"] {
  grid-column: 3/5;
}

.fieldsContainer [data-grid="saveButton"] {
  grid-column: 1/-1;
  justify-self: flex-end;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
}

@media (max-width: 426px) {
  .fieldsContainer {
    gap: 10px;
  }

  .fieldsContainer [data-grid="minPrice"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="maxPrice"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="commissionValue"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="table"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="formula"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="enterprise"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="color"] {
    grid-column: 1/-1;
  }

  .fieldsContainer [data-grid="saveButton"] {
    margin-top: 10px;
  }
}
