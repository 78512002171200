/* Estilos da bolinha da faixa */

.commissionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.commissionBlock > p {
  position: relative;
  font-size: 0.625rem;
  margin: 5px 0;
}

.commissionBlock:hover > p {
  transform: scale(1.5);
  transition: all 0.2s linear;
}

.commissionBlock.isActive {
  border: 1px solid var(--gray-9);
  box-shadow: inset 0 0 4px var(--gray-10);
  border-radius: 8px;
  padding: 0 1.25rem;
}

.commissionBlock.isActive > p {
  transform: scale(1.5);
  font-weight: 800;
  transition: all 0.2s linear;
}

.commissionBlock:hover .ball,
.commissionBlock.isActive .ball {
  opacity: 0.4;
}

.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
}

.ball > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
}

.ball > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
}

.ball > p {
  position: absolute;
  color: white;
  font-weight: 800;
  font-size: 1.25rem;
  text-shadow: 0 0 0.1875rem var(--gray-0);
}

/* Estilos da bolinha da faixa */

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.625rem 1.25rem;
}

.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  text-transform: uppercase;
  gap: 0.625rem;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: 10px auto;
}

.rangeContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 0;
  box-shadow: var(--gray-9) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border-radius: 4px;
  padding: 20px 40px;
}

.newBallContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newBall {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 3.125rem;
}

.newBall > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--gray-9);
}

.newBall > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
  background-color: var(--gray-9);
}

.newBallContainer:hover .newBall > div {
  animation: rotate360 0.4s forwards ease-out;
}

.editableContainer {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: var(--gray-9) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  padding: 10px;
}

.editableContainer__mainContainer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;
  /* gap: 100px; */
  gap: 0.625rem;
}

.rangeFieldsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.rangeFieldsContainer__ballContainer {
  display: flex;
  align-items: center;
}

.rangeFieldsContainer .field {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.rangeFieldsContainer__hideRangeInput {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}

.buttonsRangeContainer {
  display: flex;
  gap: 10px;
  margin-top: 1.25rem;
}

.arrowButton {
  border-radius: 4px;
  border: 1px solid var(--gray-10);
  /* box-shadow: inset 0 0 4px var(--gray-9); */
  cursor: pointer;
  transition: all 0.2s linear;
}

.arrowButton:hover {
  background-color: rgb(247, 247, 247);
  transform: scale(0.99);
}

.editableContainer__range {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  padding: 20px;
  border: 1px solid var(--gray-10);
  /* border-radius: 50%; */
  border-radius: 4px;
  min-height: 18.75rem;
  /* width: 300px; */
  /* box-shadow: inset 0 0 4px var(--gray-9); */
}

.customInputNumber {
  border-radius: 4px;
  padding: 5px;
  font-size: 1rem;
  text-align: center;
  border: 1px solid var(--gray-6);
  max-width: 80%;
}

.customInputColor {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 6.25rem;
  height: 6.25rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.customInputColor::-webkit-color-swatch {
  border-radius: 50%;
  border: 1px solid var(--gray-6);
}

.customInputColor::-moz-color-swatch {
  border-radius: 4px;
}

.finishButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.disableLastRangesContainer > label {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .fieldsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 426px) {
  .rangeContainer {
    padding: 1.25rem;
  }

  .editableContainer__range .rangeFieldsContainer {
    flex-direction: column;
  }

  .editableContainer__range .rangeFieldsContainer > .field:last-child {
    flex-direction: column-reverse;
  }

  .editableContainer__mainContainer {
    gap: 0.3125rem;
  }
}
