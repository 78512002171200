.navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.mainContainer {
  margin-top: 20px;
}

.tableContainer {
  box-shadow: 0 0 8px var(--gray-8);
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.editBankButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.loadingContainer {
  height: 580px;
  justify-content: center;
}

.paginateContainer {
  margin-top: 1.25rem;
}
