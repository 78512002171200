.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form > div:first-child {
  grid-column: 1/-1;
}

.submitButtonContainer {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 2.5rem;
}
