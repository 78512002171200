.wrapper {
  display: block;
}

.input {
  display: block;
  padding: 2px 8px;
  min-height: 38px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-8);
  border-radius: 4px;
  outline: none;
  width: 100%;
  font-size: 1rem;
  line-height: 1;
}

.input:focus {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.input:global(.isFilled) {
  border-color: var(--input-color);
  box-shadow: 0 0 0 1px var(--input-color);
}

.input::placeholder {
  color: var(--gray-7);
}

.input:not(:global(.isFilled)) {
  color: var(--gray-7);
}

.input:global(.isInvalid) {
  border-color: var(--red-6);
  box-shadow: 0 0 0 1px var(--red-6);
}

.input:disabled {
  background-color: var(--gray-10);
  cursor: not-allowed;
  border-color: var(--gray-8);
  box-shadow: none;
}

.error {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-top: 4px;
  color: var(--red-6);
}
