.fieldsContainer {
  margin-top: 1.25rem;
}

.saveButtonContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}
