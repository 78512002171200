.navContainer {
  display: flex;
  justify-content: flex-end;
}

.dataContainer {
  margin-top: 20px;
  min-height: 500px;
}

.tableContainer {
  overflow-x: auto;
  box-shadow: inset 0 0 10px 8px #f5f5f5;
}

.loadingContainer {
  justify-content: center;
  min-height: 500px;
}

.editButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 100%;
  width: 1.25rem;
}
