.filtersContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px 20px;
}

.filtersContainer__description {
  grid-column: 1/4;
}

.filtersContainer__solicitationIds {
  grid-column: 4/-1;
}

.filtersContainer__sellers {
  grid-column: 1/4;
}

.filtersContainer__supervisors {
  grid-column: 4/-1;
}

.filtersContainer__initialDate {
  grid-column: 1/3;
}

.filtersContainer__finalDate {
  grid-column: 3/5;
}

.filtersContainer__status {
  grid-column: 5/-1;
}

.filtersContainer__buttonSearch {
  grid-column: 1/-1;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.seeRequestButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.paginationContainer {
  margin-top: 1.25rem;
}

.loadingContainer {
  height: 540px;
  justify-content: center;
}

.modalExcelType__title {
  text-align: center;
  color: var(--gray-6);
  font-size: 18px;
}

.modalExcelType__buttonsContainer {
  display: flex;
  gap: 10px;
}

.modalExcelType__buttonsContainer__button {
  flex: 1;
}

.modalExcelType__buttonCancel {
  width: 100%;
  margin-top: 10px;
}