.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: container 0.2s forwards;
  min-width: 400px;
  border: 4px solid var(--red-2);
  overflow: hidden;
  transition: min-width 0.1s;
}

.container:global(.showingDetails) {
  min-width: 37.5rem;
}

.details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.detailsButton {
  border: none;
  background-color: transparent;
  font-size: 0.75rem;
  color: var(--link);
  margin: 0 auto;
  cursor: pointer;
}

.detailsButton:hover {
  opacity: 0.6;
  transition: opacity 0.1s;
}

.detailsContainer {
  display: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  background-color: var(--gray-10);
  padding: 10px 20px;
  margin-top: 8px;
  border-radius: 2px;
}

.detailsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  align-items: center;
  justify-items: center;
}

.detailsList > .documentationLine {
  grid-column: 1/3;
}

.detailsList > .documentationLine > .documentationLineTitle {
  text-align: center;
}

.detailsList > .documentationLine > .documentationLineContent {
  word-break: break-all;
}

.detailsListContainer > li:first-child {
  margin-bottom: 5px;
}

.detailsContainer:global(.opened) {
  display: block;
  max-height: 500px;
}

@keyframes container {
  from {
    transform: translate3d(0, -20px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
