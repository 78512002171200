.form {
  margin-top: 20px;
  display: grid;
  gap: 20px;
}

.fieldset {
  border: none;
  padding: 20px;
  border-radius: 4px;
}

.fieldsetLegend {
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 2px;
  color: var(--gray-3);
  width: 100%;
  border-bottom: 1px solid var(--gray-3);
}

.userDetailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.userOptionsContainer {
  display: grid;
  gap: 10px;
}

.userPasswordContainer {
  display: grid;
  gap: 10px;
}

.permissionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.othersContainer > .fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.othersContainer > .fieldset > .supervisorContainer {
  grid-column: 1/-1;
}

.phoneContainer > .fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr 4fr;
  gap: 20px;
}

.checkboxContainer {
  display: flex;
  justify-content: space-around;
}

.checkboxContainer > label {
  height: fit-content;
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
}

.optionsPermissionsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.selectMultipleContainer {
  flex: 1;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.registeringUserLoadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.registeringUserLoadingContainer > span {
  margin-top: 10px;
  font-size: 1.25rem;
  color: var(--green-5);
}

@media (max-width: 768px) {
  .userDetailsContainer {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .permissionsContainer {
    grid-template-columns: 1fr;
  }

  .optionsPermissionsContainer {
    gap: 10px;
  }

  .othersContainer > .fieldset {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media (max-width: 426px) {
  .phoneContainer > .fieldset {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .phoneContainer > .fieldset > .phoneFieldContainer {
    grid-column: 1/-1;
  }
}
