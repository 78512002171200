.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsContainer > .fieldsGroup {
  display: grid;
  gap: 20px;
}

.fieldsContainer > .fieldsGroup {
  grid-template-columns: 1fr 1fr;
}

.fieldsContainer > .fieldsGroup:nth-child(2) {
  grid-template-columns: 1fr 1fr 1fr;
}

.fieldsContainer > .fieldsGroup:nth-child(2) > div:last-child {
  grid-column: 3 / 4;
}

.imageURLContainer {
  grid-column: 1/3;
}

.submitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 2.5rem;
  justify-content: center;
  height: 460px;
}

@media (max-width: 740px) {
  .fieldsContainer > .fieldsGroup:nth-child(2) {
    grid-template-columns: 1fr 1fr;
  }
  .fieldsContainer > .fieldsGroup:nth-child(2) > div:last-child {
    grid-column: auto;
  }
}
