.filtersContainer {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  gap: 20px;
  margin-bottom: 20px;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.seeRequestButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.statusContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.statusContainer__ball {
  display: block;
  height: 20px;
  width: 20px;
  border: 2px solid var(--gray-8);
  border-radius: 50%;
  cursor: pointer;
}

.statusContainer__status {
  position: absolute;
  display: none;
  top: -130%;
  font-size: 16px;
  font-weight: 500;
  background-color: white;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid var(--gray-8);
  transition: all 0.2s ease-in-out;
  user-select: none;
  animation: showStatus 0.2s ease-in-out;
  pointer-events: none;
}

.statusContainer__ball:hover + .statusContainer__status {
  display: block;
}

.paginationContainer {
  margin-top: 1.25rem;
}

.loadingContainer {
  height: 540px;
  justify-content: center;
}

.modalExcelType__title {
  text-align: center;
  color: var(--gray-6);
  font-size: 18px;
}

.modalExcelType__buttonsContainer {
  display: flex;
  gap: 10px;
}

.modalExcelType__buttonsContainer__button {
  flex: 1;
}

.modalExcelType__buttonCancel {
  width: 100%;
  margin-top: 10px;
}

/* Animação para fazer o statusContainer__status aparecer descendo */
@keyframes showStatus {
  from {
    top: -160%;
  }
  to {
    top: -130%;
  }
}
