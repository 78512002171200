.navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  min-height: 400px;
  justify-content: center;
}

.contentContainer {
  margin-top: 1.25rem;
}

.itemQuantityContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.itemQuantityContainer > p {
  color: var(--gray-2);
}

.itemQuantityContainer__select {
  border: 1px solid var(--gray-8);
  border-radius: 2px;
  cursor: pointer;
}

.itemQuantityContainer__select:focus {
  outline: none;
}

.table td {
  padding: 0.3125rem 0.75rem !important;
}

.editCommissionTableButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 100%;
  width: 1.25rem;
}

.paginationContainer {
  margin-top: 1.25rem;
}
