.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
}

.sectiontitle {
  color: var(--gray-7);
  font-size: 24px;
  text-align: center;
}

.sectionUl, .sectionUlPremiacoes {
  margin-left: 40px;
  line-height: 1.5;
  margin-top: 20px;
}

.sectionUl > li {
  font-size: 20px;
  text-align: center;
}

.sectionDetalheTitle {
  font-size: 20px;
  font-weight: bold;
}

.subSectionUl {
  margin-left: 40px;
  line-height: 1.5;
  margin-top: 10px;
}

.subSectionUl > li {
  font-size: 20px;
  list-style: circle;
}

.subSectionTitle {
  font-size: 20px;
  text-decoration: underline;
}

.sectionUlPremiacoes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.sectionUlPremiacoes > li {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  padding: 20px 40px;
}