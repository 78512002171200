.container {
  display: flex;
  flex-direction: column;
}

.filtersContainer {
  display: grid;
  gap: 10px;
}

.filtersContainer__btnSearchSolicitation {
  width: 100%;
}

.dataContainer {
  margin-top: 0px;
}

.requestsTableContainer {
  display: grid;
  gap: 2.5rem;
}

.requestTableSupervisorContainer {
  box-shadow: 0 0 4px var(--gray-8), 0 0 8px var(--gray-8);
  border-radius: 4px;
}

.supervisorTitle {
  position: relative;
  text-align: center;
  color: var(--gray-2);
  padding: 20px 0;
  background: linear-gradient(#f8f8f8 10%, white 100%);
  border-radius: 4px 4px 0 0;
}

.supervisorTitle::after {
  position: absolute;
  top: 100%;
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--gray-8);
  border-radius: 100%;
}

.requestTableSupervisorContainer .clientItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.table {
  box-shadow: 0 0 8px var(--gray-8);
}

.requestsTable td {
  padding: 0.3125rem 0.75rem;
}

.seeRequestButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.loadingRequestListContainer {
  min-height: 500px;
  display: flex;
  justify-content: center;
}

.paginationContainer {
  margin-top: 20px;
}
