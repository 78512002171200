.container {
  z-index: 1000;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px var(--gray-8);
}

.menuMobile {
  display: none;
  height: 1.5625rem;
  width: 2.1875rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menuMobile > span {
  display: block;
  background-color: var(--github-theme);
  height: 0.25rem;
  width: 100%;
  border-radius: 0.25rem;
  transition: 0.3s;
}

.menuMobile:hover > span {
  background-color: var(--gray-8);
}

.rightArea {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px 4px 0 0;
  outline: none;
  background-color: transparent;
  text-transform: capitalize;
  gap: 1.25rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem;
}

.userButton {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px 4px 0 0;
  outline: none;
  background-color: transparent;
  text-transform: capitalize;
  gap: 0.3125rem;
  padding: 0.3125rem 0.625rem;
  font-size: 1rem;
  user-select: none;
}

.userIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  background-color: var(--github-theme);
}

.userIcon > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.userOptions {
  display: none;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  top: 100%;
  right: 0;
  min-width: 100%;
  box-shadow: 0 2px 4px var(--gray-6);
  animation: showUserOptions 0.1s linear;
}

.userButton:hover {
  box-shadow: 0 0 4px var(--gray-6);
}

.userButton:hover > .userOptions {
  display: block;
}

.optionButton {
  padding: 5px 0;
  background-color: transparent;
  color: var(--gray-2);
  border-left: none;
  border-right: none;
  border-radius: 0;
  min-width: auto;
  width: 100%;
}

.pathIndicator {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  font-size: 1rem;
}

.pathIndicator > span {
  color: #727cf5;
}

.pathIndicator > span:last-child {
  color: var(--gray-6);
}

@keyframes showUserOptions {
  from {
    right: calc(-100% - 20px);
  }
  to {
    right: 0;
  }
}

@media (max-width: 1024px) {
  .menuMobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .indicationPath {
    margin-left: 1.25rem;
  }

  .pathIndicator {
    margin-left: 1.25rem;
  }
}
