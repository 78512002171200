.navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.listaGrupos {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listaGrupos__grupo {
  border: 1px solid var(--gray-8);
  border-radius: 8px;
  display: flex;
  gap: 10px;
}

.listaGrupos__grupo > p {
  font-size: 1.125rem;
  font-weight: bold;
  flex: 1;
  padding: 10px;
}

.editGrupoButton {
  min-width: 100%;
  padding: 8.5px;
}