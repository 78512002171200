.contentContainer {
  margin-top: 20px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submitButtonContainer {
  align-self: flex-end;
  justify-self: flex-end;
}

.loadingContainer {
  min-height: 500px;
  justify-content: center;
  align-items: center;
}

.formFieldsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}

.formFieldsContainer [data-grid="status"] {
  width: 17.5rem;
  justify-self: flex-end;
}
