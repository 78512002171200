.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.formContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.formContainer__status {
  grid-column: 2/-1;
  width: 60%;
  justify-self: flex-end;
}

.formContainer__nomePermissao {
  grid-column: 1/-1;
}

.formContainer__descricaoPermissao {
  grid-column: 1/-1;
}

.formContainer__descricaoPermissao__field {
  resize: vertical;
}