.navContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.625rem;
  margin-top: -0.625rem;
}

.table td {
  padding: 0.3125rem 0.75rem;
}

.editButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}
