.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsContainer > .fieldsGroup {
  display: grid;
  gap: 20px;
}

.fieldsContainer > .fieldsGroup:nth-child(1) {
  grid-template-columns: 1fr 1fr;
}

.fieldsContainer > .fieldsGroup:nth-child(2) {
  grid-template-columns: 1fr 1fr 1fr;
}

.imageURLContainer {
  grid-column: 1/3;
}

.submitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  margin-top: 2.5rem;
}

@media (max-width: 740px) {
  .fieldsContainer > .fieldsGroup:nth-child(2) {
    grid-template-columns: 1fr 1fr;
  }
  .fieldsContainer > .fieldsGroup:nth-child(2) > div:last-child {
    grid-column: 1 / 3;
  }
}
