.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.navigationContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.microName {
  text-align: center;
}

.newCostButtonContainer {
  margin-left: auto;
}

.costListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tableCost td {
  padding: 0.3125rem 0.75rem;
}

.deleteCostButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.deletingCostLoadingContainer {
  margin-top: 20px;
}

.searchingCostsLoadingContainer {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noCostsMessage {
  display: block;
  font-size: 1.25rem;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-2);
  box-shadow: 0 2px 8px var(--gray-9);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.paginationContainer {
  margin-top: 20px;
}
