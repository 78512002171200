.navigationContainer {
  display: flex;
  justify-content: flex-end;
  gap: 0.625rem;
}

.filterContainer {
  display: flex;
  gap: 20px;
}

.filterContainer > div:first-child {
  min-width: 200px;
}

.filterContainer > div:last-child {
  flex: 1;
}

.itemQuantityContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.itemQuantityContainer > p {
  color: var(--gray-2);
}

.itemQuantityContainer__select {
  border: 1px solid var(--gray-8);
  border-radius: 2px;
  cursor: pointer;
}

.itemQuantityContainer__select:focus {
  outline: none;
}

.tableContainer {
  margin-top: 20px;
  min-height: 35rem;
}

.tableClients td {
  padding: 0.3125rem 0.75rem;
}

.deleteClientButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
  width: 6.25rem;
}

.paginationContainer {
  margin-top: 20px;
}
