/* Estilos da bolinha da faixa */

.commissionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.commissionBlock > p {
  position: relative;
  font-size: 0.625rem;
  margin: 5px 0;
}

.commissionBlock:hover > p {
  transform: scale(1.5);
  transition: all 0.2s linear;
}

.commissionBlock.isActive {
  border: 1px solid var(--gray-9);
  box-shadow: inset 0 0 4px var(--gray-10);
  border-radius: 8px;
  padding: 0 1.25rem;
}

.commissionBlock.isActive > p {
  transform: scale(1.5);
  font-weight: 800;
  transition: all 0.2s linear;
}

.commissionBlock:hover .ball,
.commissionBlock.isActive .ball {
  opacity: 0.4;
}

.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
}

.ball > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
}

.ball > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
}

/* Estilos da bolinha da faixa */

.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  text-transform: uppercase;
  gap: 0.625rem;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: 10px auto;
}

.afterSellerContainer {
  margin-top: 10px;
}

.rangeContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 0;
  box-shadow: var(--gray-9) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border-radius: 4px;
  padding: 20px 40px;
}

.commissionsMainContainer {
  margin-top: 0.625rem;
  box-shadow: var(--gray-9) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  padding: 10px;
}

.navigateRangeContainer {
  display: grid;
  gap: 0.625rem;
  grid-template-columns: auto 1fr auto;
}

.selectedRangeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  padding: 20px 0;
  border-radius: 4px;
  border: 1px solid var(--gray-10);
}

.selectedRangeContainer__ball {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 6px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowButton {
  border-radius: 4px;
  border: 1px solid var(--gray-10);
  /* box-shadow: inset 0 0 4px var(--gray-9); */
  cursor: pointer;
  transition: all 0.2s linear;
}

.arrowButton:hover {
  background-color: rgb(247, 247, 247);
  transform: scale(0.99);
}

.participantsFieldContainer {
  margin-top: 0.625rem;
}

.commissionContainer {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.participantContainer {
  padding: 8px;
  padding-top: 4px;
  border-radius: 4px;
  box-shadow: inset 0 0 4px var(--gray-7);
}

.participantContainer__nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.participantContainer__participantName {
  font-size: 1.125rem;
}

.participantContainer__removeParticipantButton {
  transition: all 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantContainer__removeParticipantButton:hover {
  box-shadow: inset 0 0 4px var(--gray-4);
  cursor: pointer;
  border-radius: 4px;
  background-color: red;
}

.participantContainer__removeParticipantButton:hover > svg > * {
  stroke: white !important;
}

.participantContainer__participantNameSeparator {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--gray-8);
  border-radius: 1px;
  margin-bottom: 4px;
}

.participantContainer__fieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.participantContainer__isSellerField {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  user-select: none;
  cursor: pointer;
}

.participantsMessage {
  grid-column: 1/-1;
}

.concludeButtonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}
