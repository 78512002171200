.formContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
}

.formContainer__customers {
  grid-column: 1/-1;
}

.formContainer__button {
  width: 100%;
  margin-top: 10px;
  grid-column: 1/-1;
}