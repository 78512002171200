.navigationContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
}

.systemTitle {
  font-size: 1.125rem;
}

.permissionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  height: 480px;
  margin-top: 0.625rem;
}

.permissionsContainer > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: fit-content;
}

.permissionsTitle {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  border-top: 1px solid var(--gray-8);
  border-bottom: 1px solid var(--gray-8);
  text-transform: uppercase;
  padding: 10px;
}

.permissionGroups {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
  flex: 1;
  overflow: auto;
  word-break: break-all;
}

.permissionGroups > li {
  border: 2px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
}

.permissionGroups > li:not(:first-child) {
  margin-top: 10px;
}

.permissionGroups > li > .permissionGroupsTitle {
  border: 1px solid var(--gray-8);
  border-bottom: 0;
  background-color: var(--gray-10);
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.permissions {
  flex: 1;
  overflow: auto;
  word-break: break-all;
}

.permissions > li {
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--gray-8);
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
}

.permissionInfoData {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.permissionInfoName {
  font-weight: 400;
}

.permissionInfoObs {
  font-size: 0.875rem;
  box-shadow: inset 0px 0px 4px var(--gray-6);
  border-radius: 4px;
  padding: 12px;
}

.permissionInfoSeparator {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--gray-6);
  margin: 0 0.5rem;
}

.permissionInfoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-10);
  font-size: 16px;
  width: 100px;
}

.permissionInfoButtonAdd {
  background-color: var(--green-4);
}

.permissionInfoButtonRemove {
  background-color: var(--red-4);
}

.buttonSalvar {
  margin-top: 20px;
  width: 100%;
}