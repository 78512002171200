.spinner {
  display: inline-block;
  overflow: hidden;
}

@keyframes inside {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inside div {
  position: absolute;
  animation: inside 1s linear infinite;
  border-radius: 50%;
}

.inside {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.inside div {
  box-sizing: content-box;
}
