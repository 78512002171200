.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
}

.navContainer__title {
  font-size: 20px;
}

.pagamentosContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonRemoverPagamentoContainer {
  grid-column: 1/-1;
}

.buttonRemoverPagamentoContainer__button {
  width: 100%;
  background-color: transparent !important;
  color: var(--red-6);
  font-weight: bold;
  border-color: var(--red-6);
}

.containerPremiacao {
  display: grid;
  grid-template-columns: 1fr auto auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid var(--gray-8);
}

.tituloPartePremiacao {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--gray-4);
  width: 100%;
  border-bottom: 1px solid var(--gray-8);
  padding-bottom: 4px;
}

.separadorVertical {
  display: block;
  height: 100%;
  width: 2px;
  background-color: var(--gray-9);
  margin: 0 10px;
}

.containerPremiacao__formPagamento {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
}

.containerPremiacao__formPagamento__comprovante {
  grid-column: 1/-1;
}

.containerPremiacao__formPagamento__observacao {
  grid-column: 1/-1;
}

.containerPremiacao__dadosPremiacao {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 320px;
}

.containerPremiacao__dadosPremiacao__valorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonsContainer {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.buttonAdicionarPagamento {
  flex: 1;
  background-color: transparent !important;
  color: var(--green-2);
  font-weight: bold;
  border-color: var(--green-2);
}

.buttonConcluirBaixaManual {
  flex: 1;
}

.textAreaObservacao {
  resize: vertical;
}

.customFileInput {
  position: relative;
  display: inline-block;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 2px;
}

.customFileInput input {
  position: absolute;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}

.customFileInput .fileName {
  flex: 1;
  color: #000;
  padding: 8px 12px;
  transition: background-color 0.2s linear;
}

.customFileInput .fileName:hover {
  background-color: var(--gray-10);
}

.customFileInput__clickableArea {
  display: flex;
  justify-content: space-between;
}

.removeFileButton {
  background-color: var(--red-6);
  color: white;
  border: none;
  border-radius: 0px 4px 4px 0px;
  padding: 4px 16px;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.removeFileButton:hover {
  background-color: var(--red-4);
}