.container {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.searchContainer {
  margin-bottom: 20px;
}

.priceTableListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.priceTable td {
  padding: 0.3125rem 0.75rem;
}

.editPriceTableButton {
  margin: auto;
  padding: 0.5rem 0.75rem;
  min-width: 80%;
}

.searchingPriceTablesLoadingContainer {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noPriceTableMessage {
  display: block;
  font-size: 1.25rem;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-2);
  box-shadow: 0 2px 8px var(--gray-9);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.paginationContainer {
  margin-top: 20px;
}

.modalReportButtonContainer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}