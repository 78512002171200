.container {
  position: relative;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.indicator:hover {
  background-color: var(--gray-10);
}

.applicationsContainer {
  width: 500px;
  height: 325px;
  display: none;
  position: absolute;
  right: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.frame {
  border: 0px;
  width: 100%;
  height: 100%;
}