.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.formContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.formContainer__status {
  grid-column: 2/-1;
  width: 60%;
  justify-self: flex-end;
}