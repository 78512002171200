.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
}

.timeline::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  left: calc(50% - 2px);
  top: 0;
}

.timelineItem {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  margin: 10px 0;
}

.timelineDot {
  position: relative;
  grid-column: 2;
  z-index: 10;
  min-height: 20px;
  min-width: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background-color: #ffffff;
}

.timelineContent {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  max-width: 70%;
}

.timelineContent::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  right: -7.5px;
  top: calc(50% - 7.5px);
  background-color: #ffffff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
}

.timelineItem:nth-child(even) .timelineContent {
  grid-column: 3;
  justify-self: flex-start;
  align-items: flex-start;
}

.timelineItem:nth-child(even) .timelineContent::after {
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .timelineContent {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 426px) {
  .timeline {
    margin: 0 30px;
  }

  .timeline::after {
    left: 0;
  }

  .timelineItem {
    grid-template-columns: auto 1fr;
  }

  .timelineDot {
    left: calc(-50% + 2px);
    grid-row: 1;
    grid-column: 1;
  }

  .timelineContent,
  .timelineItem:nth-child(even) .timelineContent {
    grid-column: 2;
    justify-self: flex-start;
    align-items: flex-start;
  }

  .timelineContent::after {
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
  }
}
