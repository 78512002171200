.modal {
  border: none;
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  justify-content: center;
  padding: 40px;
  /* ___ */
  max-width: none;
  max-height: none;
}

.modal[open] {
  display: flex;
}

.modalContent {
  position: relative;
  width: fit-content;
  height: fit-content;
  min-width: 500px;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 4px solid var(--gray-2);
}
