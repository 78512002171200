.navContainer {
  display: flex;
  justify-content: flex-end;
}

.systemTitle {
  font-size: 1.125rem;
}

.permissionGroups {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
  flex: 1;
  overflow: auto;
  word-break: break-all;
}

.permissionGroups > li {
  border: 2px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
}

.permissionGroups > li:not(:first-child) {
  margin-top: 10px;
}

.permissionGroups > li > .permissionGroupsTitle {
  border: 1px solid var(--gray-8);
  border-bottom: 0;
  background-color: var(--gray-10);
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.permissions {
  flex: 1;
  overflow: auto;
  word-break: break-all;
}

.permissions > li {
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--gray-8);
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
}

.permissionInfoSeparator {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--gray-6);
  margin: 0 0.5rem;
}

.permissionInfoData {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.permissionInfoName {
  font-weight: 500;
  font-size: 1.125rem;
}

.permissionInfoObs {
  font-size: 1rem;
  box-shadow: inset 0px 0px 4px var(--gray-6);
  border-radius: 4px;
  padding: 12px;
}

.permissionInfoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-10);
  font-size: 24px;
  min-width: auto;
  width: 50px;
  height: 80px;
}