.navContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 0.625rem;
}

.navContainer > div:last-child {
  display: flex;
  justify-content: flex-end;
}

.filtersContainer {
  margin-top: 20px;
}

.contentContainer {
  margin-top: 10px;
  min-height: 400px;
}

.contentContainer > ul {
  padding: 10px;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itemContainer {
  position: relative;
  box-shadow: 0 0 4px var(--gray-8), 2px 0 4px var(--gray-8), -2px 0 4px var(--gray-8);
  border-radius: 4px;
}

.itemContainer > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.itemTitle {
  font-size: 24px;
  font-weight: bold;
}

.itemSeparator {
  display: block;
  margin: 10px 0;
  height: 2px;
  border-radius: 100%;
  width: 100%;
  background-color: var(--gray-8);
}

.itemOptionsContainer {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid var(--gray-9);
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  gap: 10px;
}

.itemOptionsContainer > span {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: all 0.1s linear;
  overflow: hidden;
}

.itemOptionsContainer:hover > span {
  top: 100%;
}

.commissionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.commissionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.commissionBlock > p {
  position: relative;
  font-size: 0.625rem;
  margin: 5px 0;
}

.commissionBlock:hover > p {
  transform: scale(1.5);
  transition: all 0.2s linear;
}

.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
}

.ball > div {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  position: absolute;
}

.ball > span {
  display: block;
  width: 5.625rem;
  height: 0.625rem;
}

.ball > p {
  position: absolute;
  color: white;
  font-weight: 800;
  font-size: 1.25rem;
  text-shadow: 0 0 0.1875rem var(--gray-0);
}

.loadingContainer {
  min-height: 400px;
  justify-content: center;
}

.paginationContainer {
  margin-top: 1.25rem;
}
