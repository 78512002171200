.commissionBallRoot {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.commissionBallBall {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.125rem;
    width: 110px;
}

.commissionBallBall__ball {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.commissionBallBall__bar {
    display: block;
    position: absolute;
    width: 110px;
    height: 15px;
}

.commissionBallBall__letter {
    position: absolute;
    color: white;
    font-weight: 800;
    font-size: 1.25rem;
    text-shadow: 0 0 0.1875rem var(--gray-0);
}