.separator {
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--gray-8);
  border-radius: 100%;
  margin: 20px 0;
}

.title {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--gray-2);
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.625rem;
}

.fieldsContainer__buttonContainer {
  grid-column: 1/-1;
}

.fieldsContainer__buttonContainer__button {
  width: 100%;
}

.dataContainer {
  margin-top: 1.25rem;
  display: grid;
  gap: 1rem;
  padding: 20px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.validityCollapse__container {
  padding: 20px;
  border-radius: 4px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
}

.dataContainer__tableInfo {
  text-align: left;
  display: grid;
  gap: 5px;
}

.dataContainer__tableInfo__table {
  font-size: 1.25rem;
  font-weight: 800;
  color: var(--gray-2);
}

.dataContainer__tableInfo__validity {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--gray-4);
}

.rangesContainer {
  display: grid;
  gap: 1rem;
  padding: 20px;
  border-radius: 4px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
}

.rangeCollapse__container {
  padding: 20px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border-radius: 4px;
  border: 1px solid var(--gray-8);
}

.dataContainer__rangeInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dataContainer__rangeInfo__ball {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  box-shadow: 0 0 4px var(--gray-2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0 0 0.1875rem var(--gray-0);
  font-size: 1.25rem;
}

.dataContainer__rangeInfo__percent {
  font-size: 1.25rem;
  color: var(--gray-4);
  font-weight: 500;
}

.sellersContainer {
  display: grid;
  gap: 1rem;
  padding: 20px;
  border-radius: 4px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
}

.sellerCollapse__container {
  padding: 20px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border-radius: 4px;
  border: 1px solid var(--gray-8);
}

.dataContainer__sellerInfo {
  text-align: left;
  display: grid;
  gap: 5px;
}

.dataContainer__sellerInfo__seller {
  font-size: 1.125rem;
  font-weight: 800;
  color: var(--gray-2);
}

.dataContainer__sellerInfo__percent {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-4);
}

.supervisorContainer {
  display: grid;
  gap: 1rem;
  padding: 20px;
  border-radius: 4px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border: 1px solid var(--gray-9);
}

.dataContainer__supervisorInfo {
  text-align: left;
  display: grid;
  gap: 5px;
  padding: 20px;
  box-shadow: var(--gray-8) 0px 1px 3px, var(--gray-8) 0px 1px 2px;
  border-radius: 4px;
  border: 1px solid var(--gray-8);
}

.dataContainer__supervisorInfo__supervisor {
  font-size: 1.125rem;
  font-weight: 800;
  color: var(--gray-2);
}

.dataContainer__supervisorInfo__percent {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-4);
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}
