.container {
  display: flex;
  justify-content: center;
}

.pagination {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  min-height: 2.125rem;
}

.navigateButton {
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--default-color);
  color: var(--gray-10);
  cursor: pointer;
  height: 100%;
  border: 1px solid var(--default-color);
  transition: background-color 0.3s;
}

.navigateButton:hover {
  background-color: var(--default-color);
}

.navigateButton:disabled {
  opacity: 0.5;
  cursor: wait;
}

.prevPageButton {
  border-radius: 4px 0 0 4px;
}

.nextPageButton {
  border-radius: 0 4px 4px 0;
}

.pagination > ul {
  display: flex;
  height: 100%;
}

.pagination > ul > li {
  height: 100%;
}

.pageButton {
  font-size: 0.875rem;
  font-weight: 400;
  min-width: 2.125rem;
  padding: 0.375rem 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--gray-0);
  cursor: pointer;
  border: 1px solid var(--gray-8);
  transition-property: border-color, box-shadow;
  transition-duration: 0.3s, 0.3s;
}

.pageButton:global(.active) {
  border-color: var(--default-color);
  box-shadow: inset 0 0 4px 1px var(--default-color), inset 0 0 20px 1px var(--default-color);
  font-weight: 600;
  color: var(--gray-2);
}

.pageButton:hover {
  border-color: var(--default-color);
}

.pageButton:hover:not(:global(.active)) {
  box-shadow: inset 0 0 4px 1px var(--default-light-color);
  color: var(--default-light-color);
}

.pageButton:disabled {
  cursor: wait;
}

@media (max-width: 426px) {
}
