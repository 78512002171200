.filtersContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
  margin-bottom: 20px;
}

.searchPermissionsButton {
  grid-column: 1/-1;
}

.searchPermissionsButton > button {
  width: 100%;
}

.form {
  margin-top: 20px;
}

.checkboxAdicionarGrupoPermissoes {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.containerAdicionarGrupoPermissoes {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gruposPermissoesContainer {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  max-height: 500px;
}

.gruposPermissoesContainer > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  gap: 10px;
}

.gruposPermissoesContainer__label {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  color: var(--gray-6);
  transition: all 0.1s linear;
}

.gruposPermissoesContainer__label > input {
  display: none;
}

.gruposPermissoesContainer__label[data-ativa="true"] {
  border: 1px solid var(--green-4);
  color: var(--green-2);
  box-shadow: var(--green-3) 0px 1px 4px;
}

.buttonAdicionarGrupoPermissoes {
  width: 100%;
}

.dataContainer {
  margin-top: 20px;
}

.separator {
  display: block;
  background-color: var(--gray-10);
  height: 2px;
  width: 100%;
  margin: auto;
}

.selectContainer {
  margin-top: 20px;
}

.permissionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  height: 480px;
  margin-top: 0.625rem;
}

.permissionsContainer > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: fit-content;
}

.permissionsTitle {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  border-top: 1px solid var(--gray-8);
  border-bottom: 1px solid var(--gray-8);
  text-transform: uppercase;
  padding: 10px;
}

.permissionGroups {
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
  flex: 1;
  overflow: auto;
  word-break: break-all;
}

.permissionGroups > li {
  border: 2px solid var(--gray-8);
  border-radius: 4px;
  padding: 10px;
}

.permissionGroups > li:not(:first-child) {
  margin-top: 10px;
}

.permissionGroups > li > .permissionGroupsTitle {
  border: 1px solid var(--gray-8);
  border-bottom: 0;
  background-color: var(--gray-10);
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.permissions {
  /* border: 1px solid var(--gray-8); */
  /* border-top: 0; */
  /* border-radius: 0 0 4px 4px; */
  /* padding: 0 10px 10px 10px; */
  flex: 1;
  overflow: auto;
  word-break: break-all;
}

.permissions > li {
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--gray-8);
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
}

.permissionInfoName {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.permissionName {
  font-weight: 400;
}

.permissionObs {
  font-size: 0.875rem;
  box-shadow: inset 0px 0px 4px var(--gray-6);
  border-radius: 4px;
  padding: 12px;
}

.permissionInfoSeparator {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--gray-6);
  margin: 0 0.5rem;
}

.permissions > li:not(:first-child) {
  border-top: none;
}

.unselectedUserMessage {
  display: block;
  font-size: 1.25rem;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-2);
  box-shadow: 0 2px 8px var(--gray-9);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.searchingUserPermissionsLoading {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.permissionInfoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  color: var(--gray-10);
  font-size: 16px;
  /* text-transform: uppercase; */
}

.permissionInfoButtonAdd {
  background-color: var(--green-4);
}

.permissionInfoButtonRemove {
  background-color: var(--red-4);
}

.nonePermissionsMessage {
  display: block;
  text-align: center;
  margin-top: 0.625rem;
  color: var(--gray-6);
  border: 1px solid var(--gray-8);
  border-radius: 4px;
  padding: 0.625rem;
  font-weight: 800;
  box-shadow: 0 2px 8px var(--gray-9);
  word-break: break-word;
}

.needSaveMessage {
  display: block;
  text-decoration: underline;
  text-transform: uppercase;
  margin-top: 10px;
}

.submitButton {
  margin-top: 20px;
  margin-left: auto;
}

.savingPermissionsLoadingContainer {
  height: 400px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .permissions {
    overflow-y: scroll;
  }
}

@media (max-width: 768px) {
  .permissionsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }
}
