.container {
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 20px;
  flex: 1;
}

.conferenceContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.loadingContainer {
  height: 500px;
  justify-content: center;
}

.tableInterestCalculationMetrics {
  border-collapse: collapse;
  width: 100%;
}

.tableInterestCalculationMetrics td, .tableInterestCalculationMetrics th {
  border: 1px solid var(--gray-8);
  padding: 8px;
}

.failInterestCalculationMetricsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeModalInterestCalculationMetricsButton {
  margin-top: 20px;
  width: 100%;
}