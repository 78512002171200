.container {
  position: relative;
}

.indicator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.indicator:hover {
  background-color: var(--gray-10);
}

.iconContainer {
  position: relative;
}

.notificationsIndicators {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -3px;
  right: -3px;
  background-color: var(--red-5);
  color: var(--gray-10);
  font-size: 70%;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  user-select: none;
  font-weight: bold;
  animation: newNotifications 0.5s backwards;
  line-height: 16px;
}

.notificationsContainer {
  display: none;
  position: absolute;
  background-color: #ffffff;
  top: 110%;
  right: 0;
  width: 450px;
  max-height: 800px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.notification {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  transition: 0.3s;
  gap: 2.5px;
}

.notification:not(:last-child) {
  border-bottom: 2px solid var(--gray-10);
}

.notification__message {
  padding: 10px;
}

.notification__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: fit-content;
  min-height: 100%;
  cursor: pointer;
  transition: 0.3s;
}

.notification__button:hover {
  background-color: var(--gray-10);
}

@keyframes newNotifications {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
